.referral{
    width: 100%;
}

.card{
    width: 80%;
    background: #fff;
    margin : 3rem auto;
    padding: 2rem;
    box-shadow: 0px 0px 10px #9090907a;
    border-radius: 10px;
}

.card h5{
    font-size: 16px;
    color: #1a1a1a;
}

.card p{
    font-size: 14px;
    margin: 0 0 12px 0;
    color: #909090;
    padding: 0;
    margin: 0;
}

.copyLink{
    display: flex;
    align-items: center;
    gap: 1rem;
}

@media(max-width: 737px){
    .card{
        width: 100%;
    }
    .copyLink p{
        font-size: 12px;
    }
}

