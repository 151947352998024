.trade{
    width: 100%;
    height: 100%;
    overflow-y: scroll;
}

.trade::-webkit-scrollbar{
    width: 2px;
    border-radius: 6px;
    background: #f5f5f5;
}

.trade::-webkit-scrollbar-thumb{
    background: #40106D;
}

.cardWrap{
    height: fit-content;
    width: 100%;
}

.headerText{
    width: 100%;
    margin: 0 0 2rem 0;
}

.gridWrap{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
}

.grid{
    padding: 1rem 0 0 0 ;
    width: 98%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 30px;
}

.card{
    background: #fff;
    padding: 1.5rem;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width:  100%;
    aspect-ratio: 1;
    margin: 0 0 40px 0;
    box-shadow: 2px 2px 5px rgba(64, 25, 109, 0.05);
    transition: .2s ease-in-out;
    cursor: pointer;
}

.card:hover{
    box-shadow: 2px 2px 5px rgba(64, 25, 109, 0.15);
}

.card:focus{
    border: 1px #40106D solid;
}

.cardImg{
    width: 80px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: #90909039;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media(
    min-width: 1371px){
    .cardImg{
        width: 100px;
    }
}

@media(max-width: 1370px){
    .cardImg{
        width: 70px;
    }
}

.cardImg img{
    height: 110%;
}

.card p{
    font-size: 14px;
    font-weight: 500;
}

.tradeDetails{
    width: 100%;
}

.lottieWrap{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media(max-width: 737px){
    .trade{
        height: fit-content;
        overflow: hidden;
        padding: 0 0 3rem 0;
    }
    .grid{
        width: 100%;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 10px;
    }
    .card{
        width: 100%;
        padding: 10px;
    }
}