.calc{
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    padding: 0 0 3rem 0;
}

.calc::-webkit-scrollbar{
    background: none;
    width: 2px;
}
.calc::-webkit-scrollbar-thumb{
    background: #40196D;
}   

.top{
    width: 40%;
    max-width: 430px;
    margin: auto;
    text-align: left;
}

.top h5{
    font-size: 16px;
    color: #1a1a1a;
    margin: 2rem 0 10px 0
}

.top p{
    font-size: 14px;
    color: #727272;
    font-weight: 600;
    margin: 0 0 0px 0;
}

.form {
    width: 40%;
    max-width: 430px;
    margin: 3rem auto 0 auto;
    /* background: green; */
}

.form form {
    width: 100%;
}

.labelPut {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 1rem 0
}

.labelPut label {
    font-size: 14px;
    font-weight: 600;
    color: #1a1a1a;
    margin: 0 0 6px 0;
}

.input{
    width: 100%; 
}

.input input {
    border: 1px solid #cacaca;
    border-radius: 6px;
    font-size: 14px;
    padding: 14px;
    width: 100%;
    transition: .3s ease-in-out
}

.input input:focus {
    border: 1px solid #40196D;
}

.select {
    width: 100%;
    position: relative;
}

.selectInput {
    cursor: pointer;
}

.selectInput input:disabled {
    background: #fff;
    cursor: pointer;
}

.select .option {
    position: absolute;
    width: 100%;
    background: #fff;
}

.dropDown {
    position: absolute;
    right: 4%;
    top: 50%;
    /* transform: translateY( -50%) rotate(0deg); */
    transition: .2s ease all;
}

.select input {
    border: 1px solid #cacaca;
    border-radius: 6px;
    font-size: 14px;
    padding: 14px;
    width: 100%;
    transition: .3s ease-in-out
}

.input select {
    border: 1px solid #cacaca;
    border-radius: 6px;
    font-size: 14px;
    padding: 10px;
    width: 100%;
    transition: .3s ease-in-out
}

.option {
    /* border: 1px solid #cacaca; */
    border: none;
    border-radius: 6px;
    font-size: 14px;
    padding: 0 4px;
    width: 100%;
    transition: .3s ease-in-out;
    position: absolute;
    box-shadow: 0px 6px 20px #909090;
    height: 100px;
    overflow-y: scroll;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    z-index: 1;
}

.option p {
    font-size: 14px;
    color: #717171;
    padding: 10px;
    border-radius: 4px 4px 0 0;
    border-bottom: 1px #90909042 solid;
    width: 100%;
    margin: 2px 0;
    cursor: pointer;
    transition: .2s ease-in-out;
}

.option p:hover {
    background: #f5f5f5;
}

.option::-webkit-scrollbar {
    width: 2px;
    background: #9090907a;
}

.option::-webkit-scrollbar-thumb {
    background-color: #40196D;
}

.cardItem{
    display: flex;
    align-items: center;
    padding: 4px 8px;
    width: 100%;
    border-bottom: 1px #90909039 solid;
    transition : .2s ease-in-out;
}

.cardImgDrop{
    width: fit-content;
    height: 22px;
}
.cardImgDrop2{
    position: absolute;
    width: fit-content;
    height: 22px;
    top: 50%;
    transform: translateY(-50%);
    left: 12px;
}

.cardImgDrop2 img{
    height: 100%
}

.cardItem img{
    height: 100%;
}

.cardItem p{
    border: none !important;
    width: 100%;
}

.cardItem p:hover{
    border: none !important;
    width: 100%;
    background: none;
}

.cardItem:hover{
    background: #90909039;
}

.rate{
    width: 100%;
    background: #F5F3F7;
    border-radius: 4px;
    margin: 2rem  0 0 0;
    display: flex;
    flex-direction: column;
}

.rate p{
    font-size: 15px;
    font-weight: 500;
    color: #909090;
}

.rate span{
    font-weight: 700;
    color: #1a1a1a;
    font-size: 15px;
    font-style: normal;
}


.eachRate{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin: auto;
    padding: 1.2rem 0;
}

.erHr{
    margin: 0; 
    border-top: 2px #EEEEEE solid;
}

.switch{
    width: 80%;
    margin:  0 auto 3rem auto;
}

.switchTitle{
    width: 100%;
    display: flex;
    align-items: center;
}

.switchPee{
    width: 50%;
    text-align: center;
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    color: #c1c1c1;
    cursor: pointer;
    transition: .2s ease-in-out;
}

.switchPee:hover{
    background: #c1c1c11e;
}


.switchPeeHi{
    width: 50%;
    text-align: center;
    margin: 0;
    color: #40196d;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: .2s ease-in-out;
}
.switchPeeHi:hover{
    background: #c1c1c11e;
}

.switchSlider{
    width: 100%;
    height: 2px;
    background: #c1c1c1a5;
    position: relative;
}

.switchSlideBar{
    width: 50%;
    height: 5px;
    background: #40196D;
    position: absolute;
    top: 50%;
    left:0%;
    transform: translateY(-50%);
    border-radius: 13px;
    transition: .2s ease-in-out;
}

.proceed{
    width: 100%;
    margin: 2rem 0;
    background: #40196D;
    border-radius: 6px;
    border: none;
    padding: 14px;
    color: #fff;
    transition: .2s ease-in-out;
}

.proceed:hover{
    transform: translateY(-4px)
}

.proceed:active{
    transform: translateY(-4px)
}


@media(max-width: 737px){
    .top{
        width: 100%;
    }
    .form{
        width: 100%;
    }
    .calc::-webkit-scrollbar{
        display: none;
    }
}