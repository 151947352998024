.personalInfo{
    width: 100%;
    height: 100%;
}

.piContainer{
    width: 100%;
    height: 100%;
}

.h4{
    height: 6%;
}

.bottom{
    width: 100%; 
    height: 94%;
}

.indicator{
    width: 50%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0;
}

.tickCircle{
    font-size: 26px;
    color: #40196D;
    min-width: 26px;
}

.noCircle{
    height: 25px; 
    width: 25px;
    background: #fff;
    border-radius: 50%;
    border: 1px rgba(217, 209, 226, 1) solid ;
    min-width: 25px;
    transition: .2s ease-in-out;
}

.progressCircle{
    height: 25px; 
    width: 25px;
    min-width: 25px;
    background: #fff;
    border-radius: 50%;
    transition: .2s ease-in-out;
    border: 1px #40196D solid 
}

.doneCircle{
    height: 25px; 
    width: 25px;
    background: #fff;
    border-radius: 50%;
    border: 1px rgba(217, 209, 226, 1) solid ;
    min-width: 25px;
    animation: .2s fade-in ease-in-out;
    transition: .2s ease-in-out;
}

.linkage{
    width: 100%;
    height: 2px;
    background: rgba(217, 209, 226, 1);
    transition: .2s ease-in-out;
}

.linkageDone{
    width: 100%;
    height: 2px;
    background: #40196D;
}


.dotLabels{
    display: flex;
    align-items: center;
    width: 58%;
    margin: auto;
    justify-content: space-between;
}

.dotLabels p{
    font-size: 12px;
}

.form{
    width: 40%;
    margin: 2rem auto 0 auto;
    /* background: green; */
}

.form form{
    width: 100%;
    padding: .73rem;

}

.formTP{
    width: 40%;
    margin: 5rem auto 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.formTP h4{
    font-size: 16px;
    margin: 0 0 10px 0 ;
}

.formTP p{
    font-size: 14px;
    margin: 0 0 2rem 0 ;
    color: #727272;
}

.flName{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap :2rem
}

.labelPut{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 1rem 0
}

.labelPut label{
    font-size: 14px;
    font-weight: 600;
    color: #1a1a1a;
    margin: 0 0 6px 0;
}

.input{
    width: 100%;
}

.input input{
    border: 1px solid #cacaca;
    border-radius: 6px;
    font-size: 14px;
    padding: 14px;
    width: 100%;
    transition: .3s ease-in-out
}

.input input:focus{
    border: 1px solid #1a1a1a;
}

.select{
    width: 100%;
    position: relative;
}

.selectInput{
    cursor: pointer;
}

.selectInput input:disabled{
    background: #fff;
    cursor: pointer;
}

.select .option{
    position: absolute;
    width: 100%;
    background: #fff;
}

.dropDown{
    position: absolute;
    right: 4%;
    top: 50%;
    /* transform: translateY( -50%) rotate(0deg); */
    transition: .2s ease all;
}

.select input{
    border: 1px solid #cacaca;
    border-radius: 6px;
    font-size: 14px;
    padding: 14px;
    width: 100%;
    transition: .3s ease-in-out
}

.input select{
    border: 1px solid #cacaca;
    border-radius: 6px;
    font-size: 14px;
    padding: 10px;
    width: 100%;
    transition: .3s ease-in-out
}

.option{
    /* border: 1px solid #cacaca; */
    border: none;
    border-radius: 6px;
    font-size: 14px;
    padding: 0 4px;
    width: 100%;
    transition: .3s ease-in-out;
    position: absolute;
    box-shadow: 0px 6px 20px #909090;
    height: 100px;
    overflow-y: scroll;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    z-index: 1;
}

.option p{
    font-size: 14px;
    color: #717171;
    padding: 6px;
    border-radius: 4px ;
    width: 100%;
    margin: 2px 0;
    cursor: pointer;
    transition: .2s ease-in-out; 
}

.option p:hover{
    background: #f5f5f5;
}

.option::-webkit-scrollbar{
    width: 2px;
    background: #9090907a;
}
.option::-webkit-scrollbar-thumb{
    background-color: #40196D;
}

.input select:focus{
    border: 1px solid #1a1a1a;
}

.proceed{
    width: 100%;
    background: #40196D;
    color: #fff;
    font-family: Syne, sans-serif;
    padding: 16px;
    border-radius: 6px;
    border: none;
    transition: .2s ease-in-out;
    cursor: pointer;
    margin: 10px 0 0 0; 
}

.proceed:hover {
    background: #38165f;
    margin: 8px 0 0 0; 
}

.proceed:active {
    background: #2b1148;
    margin: 10px 0 0 0; 
}

.proceed:disabled{
    background: #B1B0AE;
}

.proceedPin{
    width: 100%;
    background: #40196D;
    color: #fff;
    font-family: Syne, sans-serif;
    padding: 12px;
    border-radius: 6px;
    border: none;
    transition: .2s ease-in-out;
    cursor: pointer;
    margin: 2rem 0 0 0; 
}

.proceedPin:hover {
    background: #38165f;
    transform: translateY(-4px)
}

.proceedPin:active {
    background: #2b1148;
    transform: translateY(-1px)
}

.proceedPin:disabled{
    background: #B1B0AE;
}

.pinInput {

}

















@keyframes fade-in {
    from{
        opacity: 0.4;
    }
    to{
        opacity: 1;
    }
}