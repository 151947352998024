.kycWrap {
  background: #faf9fb;
  max-width: 604px;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 12px;
  color: #40196d;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-size: 16px;
  font-weight: 500;
}

.lvlArrow {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 22px;
}

.levelPop {
  font-size: 16px;
  font-weight: 500;
  height: 30px;
  background: #ece8f0;
  width: 90px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  cursor: pointer;
  transition: 0.2s ease-in-out;
  transform: translateX(0px);
}

.icon:hover {
  transform: translateX(2px);
}

.icon:active {
  transform: translateX(0px);
}

.modalTitle {
  width: 100%;
  text-align: center;
  color: #000;
}

.cardWrap {
  width: 100%;
  margin: 2rem 0 0 0;
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

.card {
  flex: 1;
  min-width: 202px;
  /* background: #F8F4FB; */
  background: #40196d;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 22px;
  border-radius: 12px;
  padding: 14px 12px;
  transition: 0.2s ease-in-out;
}

.cardOff {
  flex: 1;
  min-width: 202px;
  background: #f8f4fb;
  /* background: #40196D; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 22px;
  border-radius: 12px;
  padding: 14px 12px;
  transition: 0.2s ease-in-out;
}

/* .cardOff:hover {
  background: #f4eef9;
  transform: scale(1.01);
} */

.cardOff:active {
  background: #f4eef9;
  transform: scale(1);
}

.levelBadge {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.levelBadge h4 {
  margin: 0 0 0 0;
  font-weight: 600;
  font-size: 17px;
  color: #1a1a1a;
}
.levelBadge p {
  margin: 0 0 0 0;
  font-size: 12px;
  background: #4f287c;
  height: 18px;
  width: 58px;
  border-radius: 20px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.limitAmt {
  width: 100%;
  text-align: left;
}

.limitAmt h4 {
  width: 100%;
  text-align: left;
  font-size: 15px;
  margin: 0;
}

.limitAmt p {
  width: 100%;
  text-align: left;
  margin: 0;
  font-size: 13px;
  font-weight: 500;
  color: #adadad;
}

.bvn {
  width: 100%;
  position: relative;
}

.bvn h2 {
  font-size: 17px;
  color: #000000;
  font-weight: 600;
  width: 100%;
  text-align: center;
}

.subHead {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #9e9e9e !important;
  text-align: center !important;
  margin: 56px 0 !important;
}

.bvn form {
  width: 430px;
  display: flex;
  flex-direction: column;
  margin: auto;
}

.bvn form input {
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  color: #909090;
  outline: none;
  border: none;
  background: #f7f3fb;
  border-radius: 4px;
  min-height: 48px;
  margin: 8px 0;
  padding: 10px;
}

.bvn form input:focus {
  background: #f0eaf6;
}

.bvn form input[type="number"]::-webkit-outer-spin-button,
.bvn form input[type="number"]::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
  margin: 0;
}

.bvn form label {
  width: 100%;
  font-size: 14px;
  color: #1a1a1a;
  font-weight: 500;
}

.question {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px 0 60px 0 !important;
  gap: 10px;
}

.questionMark {
  width: 18px;
  height: 18px;
  background: rgba(95, 23, 127, 0.15);
  border-radius: 50%;
  font-family: Satoshi;
  color: #40196d !important;
  margin: 0 !important;
}

.questionLink {
  color: #40196d !important;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  margin: 0 !important;
}

.questionLink:hover {
  transform: translateY(-1px);
}

.questionLink:active {
  transform: translateY(0);
}

.proceed {
  width: 100%;
  margin: 0;
  background: #40196d;
  border-radius: 6px;
  border: none;
  padding: 14px;
  color: #fff;
  transition: 0.2s ease-in-out;
  cursor: pointer;
}

.proceed:hover {
  transform: translateY(-4px);
}

.proceed:active {
  transform: translateY(-2px);
}

.proceed:disabled {
  background: #909090;
}

.whyWrap {
  width: 400px;
  margin: auto;
}

.why {
  color: #5f177f !important;
  font-size: 17px !important;
  font-weight: 600 !important;
  margin: 30px 0 !important;
}

.whyContent {
  font-size: 15px !important;
  text-align: left !important;
  color: #1a1a1a !important;
  margin: 0 0 30px 0 !important;
}

.idVerification {
  width: 100%;
  min-width: 432px;
  position: relative;
}

.idvTitle {
  font-size: 17px;
  color: #000000;
  font-weight: 600;
  width: 100%;
  text-align: center;
}

.idvSubtitle {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #9e9e9e !important;
  text-align: center !important;
  margin: 56px 0 34px 0 !important;
}

.uploadSelfieWrap {
  width: 100%;
  margin: 32px 0 0 0;
}

.uploadSelfieWrap label {
  color: #1a1a1a;
  font-size: 14px;
  font-weight: 500;
}

.uploadSelfieWrap label span {
  color: #ff0000;
  font-size: 14px;
  font-weight: 500;
}

.contentIcon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  margin: 8px 0 0 0;
}

.idContent {
  font-size: 12px;
  font-weight: 500 !important;
  text-align: left !important;
  margin: 0 !important;
  color: #cfcfcf !important;
}

.idIconDiv {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px dashed #5f177f;
}

.idCamera {
  -webkit-appearance: none;
}

.changePhoto {
  border: 1px #5f177f dashed;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  background: #fff;
  cursor: pointer !important;
}

.changePhoto:hover {
  background: #00000010;
}
.changePhoto:active {
  text-decoration: none;
}

/* DROP DOWN STYLES =============================================== */

.form {
}

.form form {
  width: 100%;
}

.labelPut {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 0 1rem 0;
}

.labelPut label {
  font-size: 14px;
  font-weight: 600;
  color: #1a1a1a;
  margin: 0 0 6px 0;
}
.labelPut label span {
  color: #ff0000;
}

.input {
  width: 100%;
  position: relative;
}

.copyRef {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 4%;
  transition: 0.2s ease-in-out;
  cursor: pointer;
}

.copyRef:hover {
  opacity: 0.8;
}
.copyRef:active {
  opacity: 0.6;
}

.inputs {
  margin: 8rem 0 4rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.inputs p {
  width: 70%;
}

.inputs2 {
  margin: 26px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.inputs2 p {
  width: 70%;
}

.inputs3 {
  margin: 0px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.inputs3 p {
  width: 70%;
}

.input input {
  background: #f7f3fb;
  border-radius: 6px;
  border: none;
  font-size: 14px;
  padding: 16px 14px;
  width: 100%;
  transition: 0.3s ease-in-out;
}

.input input:focus {
  background: #ede6f4;
}

.enterFive {
  margin: 1rem 0 5rem 0;
}

.select {
  width: 100%;
  position: relative;
}

.selectInput {
  cursor: pointer;
}

.selectInput input:disabled {
  background: #fff;
  cursor: pointer;
}

.select .option {
  position: absolute;
  width: 100%;
  background: #fff;
}

.dropDown {
  position: absolute;
  right: 4%;
  top: 50%;
  /* transform: translateY( -50%) rotate(0deg); */
  transition: 0.2s ease all;
}

.select input {
  background: #f7f3fb !important;
  border-radius: 4px;
  font-size: 14px;
  padding: 16px 14px;
  width: 100%;
  border: none;
  transition: 0.3s ease-in-out;
}

.input select {
  border: 1px solid #cacaca;
  border-radius: 6px;
  font-size: 14px;
  padding: 10px;
  width: 100%;
  transition: 0.3s ease-in-out;
}

.option {
  /* border: 1px solid #cacaca; */
  border: none;
  border-radius: 6px;
  font-size: 14px;
  width: 100%;
  transition: 0.3s ease-in-out;
  position: absolute;
  box-shadow: 0px 6px 20px #909090;
  height: 100px;
  overflow-y: scroll;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  z-index: 1;
}

.option p {
  font-size: 14px;
  color: #717171;
  padding: 12px;
  border-radius: 4px 4px 0 0;
  border-bottom: 1px #90909039 solid;
  width: 100%;
  margin: 2px 0;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  text-align-last: left;
}

.option p:hover {
  background: #f5f5f5;
}

.option::-webkit-scrollbar {
  width: 2px;
  background: #9090907a;
}

.option::-webkit-scrollbar-thumb {
  background-color: #40196d;
}

.input select:focus {
  border: 1px solid #1a1a1a;
}

.lottieWrap {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lottieWrapDrop {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}



/* DROP DOWN STYLES =============================================== */

@media (max-width: 1370px) {
  .input input,
  .select input {
    padding: 14px;
  }
  .labelPut {
    margin: 10px 0;
  }
  .labelPut label {
    font-size: 12px;
  }
  .idContent {
    font-size: 12px;
  }
  .idvSubtitle {
    margin: 20px 0 !important;
  }
}

@media (max-width: 730px) {
  .idVerification {
    width: 100%;
    min-width: 100%;
  }
  .form form {
    width: 100%;
    max-width: 100%;
  }
  .levelBadge h4 {
    font-size: 14px;
  }
  .cardWrap {
    gap: 10px;
    width:100%;
  }
  .card, .cardOff{
    min-width: 50%;
  }
  .limitAmt p{
    font-size: 11px;
  }
  .whyWrap{
    width: 290px;
    min-width: 100%;
  }
  .why{
    font-size: 15px !important;
  }
  .whyContent{
    font-size: 13px !important;
    /* text-align: justify !important; */
  }
  .bvn form{
    width: 100%;
  }
}
