.promptWrap{
    width: 100%;
    height: 100vh;
    background: rgba(0, 0 ,0 ,0.5);
    position: absolute;
    top:0;
    left: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.prompt{
    width: 30%;
    aspect-ratio: 1.6;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0px 0px 8px rgba(0, 0 ,0 ,0.2);
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contents{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 2rem;
}

.buttons{
    width: 60%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 12px;
}

.proceed{
    width: 50%;
    margin: 0 auto;
    background: #40196D;
    border-radius: 6px;
    border: none;
    padding: 12px;
    color: #fff;
    cursor: pointer;
    transition: .2s ease-in-out;
}

.proceed:hover{
    transform: translateY(-4px)
}

.proceed:active{
    transform: translateY(-1px)
}

.notProceed{
    width: 50%;
    margin: 0 auto;
    background: #d6d6d6;
    border-radius: 6px;
    border: none;
    padding: 12px;
    color: #fff;
    cursor: pointer;
    transition: .2s ease-in-out;
}

.notProceed:hover{
    transform: translateY(-4px)
}

.notProceed:active{
    transform: translateY(-1px)
}

@media(max-width:737px){
    .prompt{
        width: 88%;
        padding: 12px;
        text-align: center;
    }
    .contents{
        gap: 10px;
    }
    .contents img{
        width: 30%;
    }
    .contents p{
        font-size: 12px;
    }

}