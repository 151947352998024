*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Syne, sans-serif;
}

a{
  text-decoration: none;
  transition: .2s ease-in-out;
}

button{
  font-family: Syne, sans-serif;
}

a:hover{
  text-decoration: underline;
}

a:active{
  text-decoration: none;
}

input{
  /* border: none; */
  outline: none;
  background: white;
  
}
input:focus {
  border: none;
  outline: none;
  background: white;
}

.full-w{
  width: 100%;
}

.full-h{
  height: 100%;
}

.container{
  width: 88%;
  margin: auto;
  max-width: 1200px;
}

.pincode-input-container{
  display: flex;
}

.pincode-input-container .pincode-input-text {
  padding: 0 !important;
  margin: 0 10px !important;
  text-align: center;
  background: transparent;
  border-radius: 4px;
  width: 46px !important;
  height: 46px !important;
}

.pincode-input-container .pincode-input-text:focus {
  outline: none;
  box-shadow: none;
}


input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  display: none; 
  -webkit-appearance: none;
  -moz-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield;
}

@media(max-width: 737px){

  .container{
    width: 96%;
  }
  
  .pincode-input-container .pincode-input-text {
    padding: 0 !important;
    margin: 0 4px !important;
    text-align: center;
    background: transparent;
    border-radius: 4px;
    width: 36px !important;
    height: 36px !important;
    
  }

}