.sidebar{
    flex: 1;
    background: rgba(64, 25, 109, 0.02);
    height: 100%;
}

.sb{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.logo {
    width: 100%;
    padding: 0px 30px 0px;
}

.logo img{
    width: 50%;
    margin: auto;
}
/*  */

.hr{
    border: none;
}

.top{
    width: 88%;
    margin: auto;
    height: 10%;
    /* background: green; */
    border-bottom: 0.5px rgba(26, 26, 26, 0.1) solid;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.listWrap{
    height: 90%;
    overflow-y: scroll;
}

.list{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 0;
    margin: auto;
    width: 88%;

}

.listWrap::-webkit-scrollbar{
    width: 4px;
}

.listWrap::-webkit-scrollbar-thumb{
    width: 4px;
}

.button {
    width: 100%;
    color: rgb(26, 26, 26, .9);
    display: flex;
    align-items: center;
    padding: 14px 30px 14px;
    gap:10px;
    border-radius: 10px;
    transition: .2s ease-in-out;
    cursor: pointer;
}

.button p{
    font-size: 14px;
    white-space: nowrap;
}

.buttonHi p{
    font-size: 14px;
    white-space: nowrap;
}

.button:hover{
    background: rgba(26, 26, 26, 0.08);
}

.buttonHi {
    width: 100%;
    background: #40196D;
    color: #fff;
    display: flex;
    align-items: center;
    padding: 14px 30px 14px;
    gap:10px;
    cursor: pointer;
    border-radius: 10px;
    transition: .2s ease-in-out;
}

.mobClose{
    display: none
}

.webSettings{
    width: 100%;
    color: rgb(26, 26, 26, .9);
    display: flex;
    align-items: center;
    padding: 14px 30px 14px;
    gap:10px;
    border-radius: 10px;
    transition: .2s ease-in-out;
    cursor: pointer;
}

.webSettingsHi{
    width: 100%;
    color: rgb(26, 26, 26, .9);
    display: flex;
    align-items: center;
    padding: 14px 30px 14px;
    gap:10px;
    border-radius: 10px;
    transition: .2s ease-in-out;
    cursor: pointer;
    background: #40196D;
    color: #fff;
    transition: .2s ease-in-out;
}

.mobSettings{
    display: none;
}

.mobSettingsHi{
    display: none;
}

.settingsDD{
    display: none;
}


@media(max-width:737px){
    
    .webSettings{
        display: none !important;
    }
    .webSettingsHi{
        display: none !important;
    }
    .mobClose{
        display: block;
    }
    .sidebar{
        position: fixed;
        top: 0;
        width: 250px;
        background: #FAF9FB;
        z-index: 1;
        transition: .3s ease-in-out;
        box-shadow: 0px 0px 10px #d3d3d3;
    }
    .top{
        justify-content: flex-end;
    }
    .logo{
        display: flex;
    }
    .mobSettings{
        width: 100%;
        color: rgb(26, 26, 26, .9);
        display: flex !important;
        align-items: center;
        padding: 14px 30px 14px;
        gap:10px;
        border-radius: 10px;
        transition: .2s ease-in-out;
        cursor: pointer;
    }
    
    .mobSettings:hover{
        background: rgba(26, 26, 26, 0.08);
    }
    
    .mobSettingsHi{
        width: 100%;
        color: rgb(26, 26, 26, .9);
        display: flex;
        align-items: center;
        padding: 14px 30px 14px;
        gap:10px;
        border-radius: 10px;
        transition: .2s ease-in-out;
        cursor: pointer;
        background: #40196D;
        color: #fff;
        transition: .2s ease-in-out;
    }
    
    .mobSettings p, .mobSettingsHi p{
        font-size: 14px;
    }
    .settingsDD{
        background: #f5f5f5;
        width: calc(100% - 40px);
        margin: 0 0 0 auto;
        height: fit-content;
        overflow: hidden;
        transition: .3s ease-in-out;
        display: flex;
        flex-direction: column;
    }
    .settingsDDp{
        margin: 4px 2px;
        padding: 12px;
        font-size: 14px;
        border-radius: 4px;
        cursor:pointer;
        transition: .2s ease-in-out;
        white-space: nowrap;
    }
    
    .settingsDDHip{
        background: #40196D;
        margin: 4px 2px;
        padding:  12px;
        font-size: 14px;
        border-radius: 4px;
        cursor:pointer;
        transition: .2s ease-in-out;
        color: #fff;
        white-space: nowrap;
    }

    .settingsDDp:hover{
        background: rgba(26, 26, 26, 0.08);
    }

}

/* <HambergerMenu size="32" color="#FF8A65" variant="Outline" /> */