.bills{
    width: 100%;
    height: 100%;
    padding: 0 0 1.25rem 0;
    overflow-y: scroll;
}

.bills::-webkit-scrollbar{
    display: none;
}

.bButtons{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px
}

.button{
    padding: 10px 50px;
    background: #fff;
    border: 2px solid #D9D1E2;;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    cursor: pointer;
    max-width: 150px;
    transition: .2s ease-in-out
}

.button:hover{
    border: 2px solid #b392d8;
}

.buttonHi{
    padding: 11px 50px;
    background: #fff;
    border: 2px solid #40196D;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    cursor: pointer;
    max-width: 150px;
    transition: .2s ease-in-out;
}

.icon{
    background: #FAF9FB;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    color: #40196D;
    padding: 10px;
}

.button p{
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
}

.buttonHi p{
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
}

/* ============================= THE FORM STYLES =========================== */

.buttom{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: skyblue; */
}

.form{
    width: 40%;
    margin: 2rem 0 0 0;
}

.form form{
    width:100%;
}

.labelPut{
    width:100%;
    display: flex;
    flex-direction: column;
    margin: 12px 0 0 0;
}

.labelPut label{
    font-size: 12px;
    font-weight: 500;
}

.input{
    width: 100%;
    margin: 2px 0 0 0;
}

.input input{
    width: 100%;
    padding: 14px;
    border-radius: 4px;
    border: 1px #cacaca solid;
}

.input input:focus{
    border: 1px #40196D solid;
}

.input select{
    width: 100%;
    padding: 14px;
    border-radius: 4px;
    border: 1px #909090 solid;
}

.input select:focus{
    border: 1px #40196D solid;
}

.select {
    width: 100%;
    position: relative;
}

.selectInput {
    cursor: pointer;
}



.selectInput input:disabled {
    background: #fff;
    cursor: pointer;
}

.select .option {
    position: absolute;
    width: 100%;
    background: #fff;
}

.dropDown {
    position: absolute;
    right: 4%;
    top: 50%;
    /* transform: translateY( -50%) rotate(0deg); */
    transition: .2s ease all;
}

.selectActive {
    border: 1px solid #40196D;
    border-radius: 6px;
    font-size: 14px;
    padding: 14px;
    width: 100%;
    transition: .3s ease-in-out
}

.selectDormant {
    border: 1px solid #cacaca;
    border-radius: 6px;
    font-size: 14px;
    padding: 14px;
    width: 100%;
    transition: .3s ease-in-out
}

.input select {
    border: 1px solid #cacaca;
    border-radius: 6px;
    font-size: 14px;
    padding: 10px;
    width: 100%;
    transition: .3s ease-in-out
}

.option {
    /* border: 1px solid #cacaca; */
    border: none;
    border-radius: 6px;
    font-size: 14px;
    padding: 0 4px;
    width: 100%;
    transition: .3s ease-in-out;
    position: absolute;
    box-shadow: 0px 6px 20px #909090;
    height: 100px;
    overflow-y: scroll;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    z-index: 1;
}

.option p {
    font-size: 14px;
    color: #717171;
    padding: 6px;
    border-radius: 4px;
    width: 100%;
    margin: 2px 0;
    cursor: pointer;
    transition: .2s ease-in-out;
}

.option p:hover {
    background: #f5f5f5;
}

.option::-webkit-scrollbar {
    width: 2px;
    background: #9090907a;
}

.option::-webkit-scrollbar-thumb {
    background-color: #40196D;
}

.pay{
    width: 100%;
    background: #40196D;
    color: #fff;
    padding: 16px;
    font-family: Syne, sans-serif;
    margin-top: 20px;
    border: none;
    border-radius: 4px ;
    transition: .2s ease-in-out;
}

.pay:hover{
    transform: translateY(-4px);
}

.pay:active{
    transform: translateY(-1px);
}

@media(max-width:768px ){
    .bButtons{
        display: none;
    }
    .form{
        width:100%;
    }
}