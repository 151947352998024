.notFound{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nfMiddle{
    width:  40%;
    text-align: center;
}

.nfMiddle h2{
    font-size: 32px;
    color: #1a1a1a;
}

.nfMiddle p{
    font-size: 42px;
    font-weight: 800;
    color: #909090;
}
.nfMiddle a{
    font-size: 14px;
    font-weight: 500;
    color: #909090;
}
.nfMiddle a:hover{
    color: #40196d;
}
