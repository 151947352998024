.cashTabs{
    width: 100%;
    margin: 1.2rem 0 0 0;
}

.cashTabs ul{
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
    list-style: none;
    position: relative;
}

.cashTabs ul::after{
    content: "";
    position: absolute;
    width: 100%;
    border-bottom: 1px #90909039 solid;
    bottom: 0;
}

.cashTabs ul li{
    padding :8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor:pointer;
    transition: .2s ease-in-out;
}

.cashTabs ul li div{
    display: flex;
    gap: 10px;
    align-items: center;
}

.cashTabs ul li p{
    font-size: 14px;
    font-weight: 700;
}

.walletDiv{
    margin-top: 30px;
}

.walletCard{
    background-color: #40196D;
    min-height: 194px;
    width: 100%;
    border-radius: 12px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px;
    position: relative;
    overflow: hidden;
}

.walletArrowBg{
    position: absolute;
    bottom: 0px;
    right: 0px;
}

.walletTitle{
    color: #6C4599;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
}

.balance {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 4px 0 0 0;
  }
  
  .balance p {
    font-family: Bai Jamjuree;
    font-size: 32px;
    font-weight: 700;
    color: #fff;
    margin: 0;
  }

  .countryDiv {
    background: #522b7f;
    padding: 8px 20px;
    border-radius: 31px;
    display: flex;
    gap: 8px;
    min-width: 120px;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .currencyText{
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
  }

  .directBankDiv{
    margin-top: 30px;
    background-color: #FAF9FB;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 30px;
  }

  .cyptoBankDiv{
    margin-top: 20px;
    background-color: #FAF9FB;
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 30px;
  }

  .title{
    color: #40196D;
    font-size: 16px;
    font-weight: 600;
  }

  .title_disabled{
    color:#909090;
    font-size: 16px;
    font-weight: 600;
  }

  .subtitle{
    color: #909090;
    font-size: 13px;
    margin-top: 5px;
    max-width: 180px;
  }

  .info_div{
    margin-top: 10px;
    display: flex;
    gap: 5px;
    align-items: center;
  }

  .info_div p{
    color: #5F177F;
    font-size: 12px;
  }

  .ctaDiv{
    display: flex;
    gap: 10px;
    align-items: center;
    margin-top: 0px;
  }

  .ctaBtn{
    background-color: #ECE8F0;
    border-radius: 20px;
    color: #40196D;
    font-size: 15px;
    padding: 10px 30px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    text-decoration: none;
    border: none
  }

  .ctaBtn_disabled{
    background-color: #ECE8F0;
    border-radius: 20px;
    color: #909090;
    font-size: 15px;
    padding: 10px 30px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    text-decoration: none;
    border: none;
    pointer-events: none;
  }

  .ctaDiv img {
    transition: transform 0.3s ease; /* Smooth transition for the arrow */
  }
  
  .ctaDiv:hover img {
    transform: translateX(5px); /* Move arrow slightly to the right on hover */
  }

  .animate_spin{
    animation: spin 1s linear infinite;
}

@keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }


.save{
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    padding :16px;
    border-radius: 4px;
    border: none;
    background: #40196D;
    margin: 10px 0 0 0; 
    transition: .2s ease-in-out;
    cursor: pointer;
}

.save:hover{
    transform: translateY(-4px);
}

.save:active{
    transform: translateY(-1px);
}

.save:disabled{
    background: #909090;
    cursor: default;
}

.depositBankCard{
    background-color: #F8F4FB;
    border-radius: 12px;
    padding: 20px 20px;
    cursor: pointer;
}

.depositBankCard.active{ 
    background-color: #40196D;
}

.bank{
    color: #1A1A1A;
    font-weight: 600;
    font-size: 14px;
}

.depositBankCard.active .bank{
    color: #FFE9A9;
} 

.depositBankCard.active .bankfee{
    color: #ADADAD;
} 


.bankfee{
    color: #ADADAD;
    font-size: 13px;
    font-weight: 500;
    margin-top: 4px;
}

.payoutDiv{
  background-color: #40196D1A;
  border-radius: 4px;
  padding: 14px 16px;
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 10px;
  max-height: 50px;
}

.payoutDiv  p{
  color: #909090;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 0px;
}

/* input styles */
label{ 
color: #1A1A1A;
font-size: 14px;
font-weight: 500;
}

.labelPut{
  font-size: 14px;
  font-weight: 500;
  color: #1a1a1a;
  margin-bottom: 20px;
}

.input{
  width: 100%;
  position: relative;
  margin-top: 10px;
}

.input input{
  border-radius: 4px;
  font-size: 14px;
  padding: 16px 16px;
  min-height: 50px;
  width: 100%;
  background-color: #F7F3FB;
  border: none;
  font-weight: 400;
  transition: .3s ease-in-out;
}

.input input::placeholder{
  color: #909090;
  font-weight: 400;
  font-size: 14px;
}

.lottieWrap {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media(max-width:737px){
    .cashTabs ul{
        white-space: nowrap;
        overflow-x: auto;
    }

    .directBankDiv{
        flex-direction: column;
    }   
    .cyptoBankDiv{
        flex-direction: column;
    }
    .ctaDiv{
        margin-top: 15px;
    }
    .walletArrowBg{
        width: 150px;
    }
}