.right{
    flex: 4.56;
    height: 100%;
    padding: 1.25rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.top{
    width: 100%;
    height: 10%;
}

.bottom{
    height: 90%;
    width: 94%;
    /* background: orange; */
    margin: auto;
}

@media(max-width: 737px){
    .right{
        padding: 0px;
        flex: 1;
        width: 100%;

    }
    .top{
        position: -webkit-sticky;
        top: 0;
        left: 0;
    }
    .bottom{
        padding: 1rem 0 0 0;
    }
}