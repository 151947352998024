.navbar{
    width: 100%;
    height: 100%;
    /* position: relative; */
}

.closeDrop{
    position: absolute;
    /* background: rgba(0, 128, 0, 0.374); */
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1;
    /* display: none; */
}

.navContainer{
    width: 94%;
    margin:  auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.left{
    flex:1;
}

.right{
    display: flex;
    align-items: center;
    gap:2rem;
}

.profileInfo{
    display: flex;
    align-items: center;
    gap: 1.4rem;
    background: #fafafa;
    padding: 8px 16px 8px 8px;
    border-radius: 100px;
    position: relative;
    cursor: pointer;
}

.profileInfo p{
    font-size: 16px;
    font-weight: 600;
}

.dropdown{
    position: absolute;
    top: 10%;
    right: 4%;
    width: 232px;
    padding: 0 8px ;
    border-radius: 12px;
    background: #fff;
    box-shadow: 0px 4px 10px #9090902d;
    transition: .2s ease-in-out;
    height: 0;
    overflow: hidden;
    z-index: 2;
}

.dropdown h5{
    font-size: 16px;
    border-bottom: 1px;
    padding: 8px 8px 16px 8px;
    border-bottom: 1px #EDF2F7 solid;
}

.dropCon{
    font-size: 20px;
    color: #718096;
    cursor: pointer;
    transition: .2s ease-in-out;
}

.profileInfo:hover .dropCon{
    transform: translateY( 4px);
}

.linkWrap{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 14px;
    margin: 0 0 16px 0;
}

.link{
    display: flex;
    align-items: center;
    gap: 18px;
    padding: 8px;
    width: 100%;
    border-radius: 6px;
    cursor: pointer;
    transition: .2s ease-in-out;
}

.link:hover{
    background: #f5f5f5;
}

.link p{
    font-size: 14px;
    font-weight: 500;
}

.icons1{
    background: #fafafa;
    height: 48px;
    width: 48px;
    border-radius: 50%;
    padding: 10px;
    position: relative;
    transition: .2s ease-in-out;
}

.icons1:hover{
    background: #dbdbdb;
}

.notifs{
    position: absolute;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px #90909039;
    top: 110%;
    right: 0;
    padding: 0px;
    height: 0px;
    width: 340px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    transition: .2s ease-in-out;
    z-index: 2;
}

.notifsWrap{
    width: 100%;
}

.notifsWrap{
    width: 100%;
}

.dot{
    background: #FF4747;
    border: 1.5px #fafafa solid;
    height: 14px;
    width: fit-content;
    min-width: 14px;
    border-radius: 500px;
    font-size: 10px;
    position:absolute;
    padding: 0 2px 2px 2px;
    top :20%;
    right: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff; 
    font-weight: 600;
    cursor: pointer;
}

.ham{
    display: none;
}

.prImg{
    height :32px;
    width: 32px;
    border-radius: 3000px;
    overflow: hidden;
}

.prImg img{
    width: 100%
}

.prImg2{
    display :none;
    height :28px;
    width: 28px;
    border-radius: 3000px;
    overflow: hidden;
}

.prImg2 img{
    width: 100%
}


@media(max-width:737px){
    .navbar{
        display: flex;
        box-shadow: 0px 0px 10px #909090;
        background: #fff;
    }
    .left h4{
        display: none;
    }
    .profileInfo{
        display: none;
    }
    .right{
        gap: 10px
    }
    .icons1{
        width: 32px;
        height: 32px;
        padding: 4px;
    }
    .dot{
        right:10%;
    }
    .ham{
        display: block;
        background: #fafafa;
        border-radius: 1000px;
        width: 32px;
        height: 32px;
        padding: 4px;
    }
    .prImg2{
        display : block !important ;
        height :32px;
        width: 32px;
        border-radius: 3000px;
        overflow: hidden;
        cursor: pointer;
    }
    .dropdown{
        width: 200px;
        right: 5%;
    }
    
}