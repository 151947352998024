.settings{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    gap: 66px;
}

.panel{
    max-width: 244px;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap : 12px;
    background: #fcfcfd;
    padding: 40px 20px
}

.button{
    display: flex;
    align-items: center;
    gap: 14px;
    cursor: pointer;
    transition: .2s ease-in-out;
    padding: 10px;
    border-radius: 6px;
    width: 100%;
}

.button:hover{
    background: #f4f4f4 !important ; 
    color: #40196D !important ;
}

.button:active{
    color: #40196D !important ;
}

.button p{
    font-size: 14px;
}

.displayWrap{
    overflow-y: scroll;
    flex:3;
    height: 100%;
}

.display{
    background: #FAF9FB;
    max-width: 604px;
    padding: 60px;
    border-radius: 12px;
}

.displayWrap::-webkit-scrollbar{
    width: 2px;
    background: none;
}

.displayWrap::-webkit-scrollbar-thumb{
    cursor: pointer;
    background: #40196D;
    border-radius: 20px;
}

/* ================================================================= PROFILE INFO STYLES */

.profile{
    width: 100%;
}

.header{
    width: 100%;
}

.header h2{
    font-size: 18px;
    font-weight: 600;
}

.header p{
    font-size: 14px;
    font-weight: 500;
    color: #909090;
}

.photo{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 44px 0 0 0;
    gap: 3rem;
}

.image{
    min-width: 100px;
    min-height: 100px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: #d9d9d9;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
}

.image img{
    height: 100%;
}

.changePhoto{
    border: 1px #1a1a1a solid;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    gap: 14px;
    border-radius: 10px ;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: .2s ease-in-out;
}

.changePhoto:hover{
    background: #00000010;
}
.changePhoto:active{
    text-decoration: none;
}

.remove{
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
}

.remove:hover{
    text-decoration: underline;
}
.remove:active{
    text-decoration: none;
}

.form {
    width: 100%;
    margin: 3rem 0 0 0;
    /* background: green; */
}

.form form {
    width: 100%;
}

.labelPut {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 1rem 0
}

.labelPut label {
    font-size: 14px;
    font-weight: 600;
    color: #1a1a1a;
    margin: 0 0 6px 0;
}

.input {
    width: 100%;
    position: relative;
}

.copyRef{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 4%;
    transition: .2s ease-in-out;
    cursor:pointer;
}

.copyRef:hover{
    opacity:.8;
}
.copyRef:active{
    opacity: .6;
}

.inputs{
    margin: 8rem 0 4rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.inputs p{
    width: 70%;
}

.inputs2{
    margin: 26px 0 0 0; 
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.inputs2 p{
    width: 70%;
}

.inputs3{
    margin: 0px 0 0 0; 
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.inputs3 p{
    width: 70%;
}

.input input {
    border: 1px solid #cacaca;
    border-radius: 6px;
    font-size: 14px;
    padding: 14px;
    width: 100%;
    transition: .3s ease-in-out
}

.input input:focus {
    border: 1px solid #40196D;
}

.enterFive{
    margin: 1rem 0 5rem  0;
}

.select {
    width: 100%;
    position: relative;
}

.selectInput {
    cursor: pointer;
}

.selectInput input:disabled {
    background: #fff;
    cursor: pointer;
}

.select .option {
    position: absolute;
    width: 100%;
    background: #fff;
}

.dropDown {
    position: absolute;
    right: 4%;
    top: 50%;
    /* transform: translateY( -50%) rotate(0deg); */
    transition: .2s ease all;
}

.select input {
    border: 1px solid #cacaca;
    border-radius: 6px;
    font-size: 14px;
    padding: 14px;
    width: 100%;
    transition: .3s ease-in-out
}

.input select {
    border: 1px solid #cacaca;
    border-radius: 6px;
    font-size: 14px;
    padding: 10px;
    width: 100%;
    transition: .3s ease-in-out
}

.option {
    /* border: 1px solid #cacaca; */
    border: none;
    border-radius: 6px;
    font-size: 14px;
    width: 100%;
    transition: .3s ease-in-out;
    position: absolute;
    box-shadow: 0px 6px 20px #909090;
    height: 100px;
    overflow-y: scroll;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    z-index: 1;
}

.option p {
    font-size: 14px;
    color: #717171;
    padding: 12px;
    border-radius: 4px 4px 0 0 ;
    border-bottom: 1px #90909039 solid;
    width: 100%;
    margin: 2px 0;
    cursor: pointer;
    transition: .2s ease-in-out;
    text-align-last: left;
}

.option p:hover {
    background: #f5f5f5;
}

.option::-webkit-scrollbar {
    width: 2px;
    background: #9090907a;
}

.option::-webkit-scrollbar-thumb {
    background-color: #40196D;
}

.input select:focus {
    border: 1px solid #1a1a1a;
}

.save{
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    padding :16px;
    border-radius: 4px;
    border: none;
    background: #40196D;
    margin: 10px 0 0 0; 
    transition: .2s ease-in-out;
    cursor: pointer;
}

.save:hover{
    transform: translateY(-4px);
}

.save:active{
    transform: translateY(-1px);
}

.save:disabled{
    background: #909090;
    cursor: default;
}

 
.save2{
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    padding :16px;
    border-radius: 4px;
    border: none;
    background: #40196D;
    margin: 2rem 0 0 0;
    transition: .2s ease-in-out;
    cursor: pointer;
}

.save2:hover{
    transform: translateY(-4px);
}

.save2:active{
    transform: translateY(-1px);
}

.save2:disabled{
    background: #909090;
}

.save3{
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    padding :16px;
    border-radius: 4px;
    border: none;
    background: #40196D;
    margin: 2rem 0 0 0;
    transition: .2s ease-in-out;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 14px;
}

.save3:hover{
    transform: translateY(-4px);
}

.save3:active{
    transform: translateY(-1px);
}


/* ============================================================== THIS IS THE CRYPTO COINS STYLES */
.cryptocoinsHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
}

.cryptocoinsHeader_border{
    border-bottom: 1px solid #F6F6F6;
}
 
.coinName{
    color: #909090;
    font-size: 14px;
}

.coinBtn{
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    padding: 12px 20px;
    border-radius: 20px;
    border: none;
    transition: .2s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 14px;
    cursor: pointer;
}


.coinBtn_notgenerate{
    color: #40196D;
    background: #ECE8F0;
}

.coinBtn_generate{
    color: #3CAF47; 
    background: #22C55E1A;
}

.coinBtn:hover{
    transform: translateY(-4px);
}

.coinBtn:active{
    transform: translateY(-1px);
}

.animate_spin{
    animation: spin 1s linear infinite;
}

@keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

/* ============================================================== THIS I THE ADD BANK STYLES */

.accounts {
    margin: 2rem 0 0 0; 
    display: flex;
    flex-direction: column;
}

.account {
    margin: 10px 0 0 0; 
    display: flex;
    align-items: center;
    justify-content: space-between; 
    border-bottom: 1px rgba(64, 25, 109, 0.05) solid ;
    padding: 22px 2rem;
}


.acLeft h6{
    font-size: 16px;
    color: #1a1a1a;
    margin: 0 0 4px 0;
    font-weight: 600;
}

.acLeft p{
    font-size: 14px;
    margin: 0 0 4px 0;
    color: #1a1a1a;
}

.acLeft span{
    font-size: 14px;
    margin: 0 0 4px 0;
    color: #727272;
    font: 600;
}

.acCon {
    font-size: 26px;
    color: #FF4747;
    cursor: pointer;
    transition: .2s ease-in-out;
}

.acCon:hover{
    color: #ad1b1b
}

.lottieWrap{
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.lottieWrapDrop{
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.lottieWrapDrop2{
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff85;
}
 
    /* ============================================================== MODAL STYLES */

.modal{
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.5);
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.modalCard{
    background: #fff;
    border-radius: 12px;
    width: 30%;
    height: 80%;
    padding: 100px 0px;
    position: relative;
    overflow-y: scroll;
}

.modalCard::-webkit-scrollbar{
    background: none;
    width: 2px;
}

.modalCard::-webkit-scrollbar-thumb{
    background: #40196D;
    
}

.closeCircle{
    color: #909090;
    position: absolute;
    right: 4%;
    top: 3%;
    cursor: pointer;
    transition:.2s ease all;
}

.closeCircle:hover{
    color: #5f5f5f;
    position: absolute;
    right: 4%;
    top: 3%;
    transform: translateY(-4px);
}

.modalCard form{
    width: 78%;
    margin: auto;

}

.modalTop{
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 12px;
}

.modalTop h5{
    font-size: 16px;
    font-weight: 600;
    color: #1a1a1a;
    margin: 0 0 12px 0;
}

.modalTop p{
    font-size: 14px;
    font-weight: 600;
    color: #727272;
    margin: 0 0 70px 0;
    line-height: 24px;
}

.collectEmail{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.collectEmail h2{
    margin: 0 0 12px 0;
}

.error {
  border: 1px red solid !important;
  /* padding: .8rem 1rem;
    border-radius: 4px; */
}

.notError {
  border: 1px rgba(51, 51, 51, 0.3) solid !important;
  padding: 0.8rem 1rem;
}

.notError:focus {
  border: 1px rgba(51, 51, 51, 0.3) solid;
  outline: none;
}

.notError input:focus {
  border: 1px rgba(51, 51, 51, 0.3) solid;
  outline: none;
}

.requestPinWrap{
    width: 100%;
}

.requestPin{
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.requestPin h2{
    font-size: 18px;
    font-weight: 600;
}

.requestPin p{
    font-size: 14px;
    font-weight: 500;
    color: #909090;
    margin: 1rem 0 5rem 0;
}

.resOtp{
    transition: .2s ease-in-out;
    cursor: pointer;
}

.resOtp:hover{
    color: #40196D;
    text-decoration: underline;
}

.abTabs{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    margin : 1rem 0 0 0; 
}

.abTab{
    width: 50%;
    padding: .6rem;
    cursor: pointer;
    border-bottom: 2px solid #d4d2d2;
    color:rgba(51, 51, 51, 0.3);
    transition: .2s ease-in-out;
}


.abTab:hover{
    background: #f4f4f4;
}

.abTabActive{
    width: 50%;
    padding: .6rem;
    cursor: pointer;
    border-bottom: 2px solid #40196D;
    background: #f4f4f4;
    color: #40196d;
    transition: .2s ease-in-out;
}


.abTabActive:hover{
    background: #f4f4f4;
    color: #40196D;
}

@media(max-width: 768px){
    .modalCard{
        width: 90%;
        padding: 3rem 0;
    }
    .modalCard form{
        width: 90%;
    }
    .modalCard::-webkit-scrollbar{
        display: none;
    }
    .panel{
        display: none
    }
    .display{
        padding: 10px
    }
    .closeCircle{
        width: 30px;
        height: 30px;
    }
    .modalTop h5{
        font-size: 14px;
        margin: 0 0 0;
    }
    .modalTop p{
        font-size: 12px;
        line-height: 16px;
        margin: 0 0 1rem 0;
        width: 100%;
    }

} 

