.emptyWrap{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.empty{
    max-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.image{
    width: 60%;
}

.image img{
    width: 100%;
    
}

.peeh{
    font-size: 14px;
    color: #909090;
}

.proceed{
    padding: 14px 32px;
    background: #40196D;
    border: none;
    border-radius: 6px;
    color: #fff;
    width: 80%;
    margin: 1rem 0 0 0;
    cursor: pointer;
    transition: .2s ease-in-out;
}

.proceed:hover{
    transform: translateY(-4px);
}

.proceed:active{
    transform: translateY(-1px);
}