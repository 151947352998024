.walletCard {
  width: 100%;
  aspect-ratio: 1.7;
  background: url("../../assets/images/wallet-card.svg");
  background-size: cover;
  padding: 30px;
  border-radius: 14px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  color: #fff;
}

.walTop {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background: green; */
}

.logoImg {
  width: 120px;
}

.popupOverlay {
  width: 100%;
  height: 100%;
  padding: 2rem;
}

.popupCard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.ppLeft {
  width: 45%;
  display: flex;
  position: relative;
}

.ppLeft img{
  z-index: 0;
}

.ppLeft::before{
  content: "";
  position: absolute;
  top: 50%;
  left:50%;
  background: #522B7F;
  height: 100%;
  aspect-ratio: 1;
  border-radius: 50%;
  transform: translate(-44%, -50%)
  ;
  z-index: 0; 
  filter: blur(84px);
}

.ppRight {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
}

.pprUp {
  width: 100%;
}

.pprUp h2 {
  font-size: 22px;
  white-space: nowrap;
}

.pprDown {
  display: flex;
  align-items: center;
  gap: 16px;
}

.pprDown img{
  cursor: pointer;
  transition : .2s ease-in-out;
}

.pprDown img:hover{
  transform: translateY(-2px)
}

.pprDown img:active{
  transform: translateY(0px)
}
/* .selectWallet {
  display: flex;
  align-items: center;
  position: relative;
}

.selectWallet p {
  margin: 0 10px 0 0;
}

.flag {
  width: 24px;
  height: 16px;
  border-radius: 20px;
  background: green;
  overflow: hidden;
  margin: 0 6px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flag img{
  width: 120%;

}

.walDrop {
  position: absolute;
  background: #fff;
  border-radius: 6px;
  display: none;
  flex-direction: column;
  height: 80px;
  width: 200%;
  overflow-y: scroll;
  top: 100%;
  right: 0;
  color: #1a1a1a;
  transition: 0.2s ease-in-out;
}

.walDrop::-webkit-scrollbar {
  background: none;
  width: 2px;
}
.walDrop::-webkit-scrollbar-thumb {
  background: #40196d4a;
}

.walDrop p {
  font-size: 12px;
  width: 100%;
  padding: 12px 10px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  margin: 0 0 0 0;
}

.walDrop p:hover {
  background: #9090902c;
}



.walArrow {
  cursor: pointer;
  transform: rotate(0deg);
  transition: 0.2s ease-in-out;
}

.walArrowActive {
  cursor: pointer;
  transform: rotate(-180deg);
  transition: 0.2s ease-in-out;
} */

.minMax {
  width: 70%;
  margin: 0 auto 2rem auto;
  display: flex;
  align-items: center;
  gap: 14px;
}

.mmPop {
  background: #fffbd2;
  width: 48%;
  padding: 10px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 737px) {
  .minMax {
    width: 94%;
  }
}

.mmPop {
  background: #f6fdf9;
  width: 48%;
  padding: 10px;
  border-radius: 6px;
}

.walEye {
  cursor: pointer;
  font-size: 30px;
  transition: 0.2s ease-in-out;
}

.walEye:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.walBottom {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 0 0 0;
}
.walBottom p {
  font-family: Syne;
  color: #6c4599;
  font-weight: 700;
  margin: 0;
}

.balance {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin: 4px 0 0 0;
}

.balance p {
  font-family: Bai Jamjuree;
  font-size: 52px;
  font-weight: 700;
  color: #fff;
  margin: 0;
}

.walletTag {
  width: 100%;
  /* background: yellow; */
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0 0 0;
  gap: 1rem;
}

.countryTag {
  width: 30%;
  background: #522b7f;
  padding: 8px 12px;
  border-radius: 300px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.countryTag2 {
  width: 100%;
  background: #522b7f;
  padding: 8px 12px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  gap: 1rem;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.countryTag2:hover {
  background: #7039af;
}

.ctImg {
  width: 32px;
  height: 32px;
}

.ctImg img {
  width: 27px;
  height: 27px;
}

.ctText {
  font-size: 18px;
  color: #fff !important;
  margin: 0 !important;
}

.ctDown {
  border-radius: 50%;
  display: flex;
  width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
  padding: 0;
  color: #5f388c;
  z-index: 0;
  position: relative;
  cursor: pointer;
  transition: 0.2s ease all;
}

.ctDown:hover .ctCon {
  color: #7244a7;
}

.ctDown:active .ctCon {
  color: #5f388c;
}

.ctCon {
  z-index: 1;
  transition: 0.2s ease all;
}

.ctCol {
  background: #fff;
  position: absolute;
  width: 20px;
  height: 20px;
  z-index: 0;
  border-radius: 50%;
}

.ctAdd {
  /* width: 36px ; */
  height: 100%;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #522b7f;
  color: #522b7f;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  z-index: 0;
  transition: 0.2s ease-in-out;
}

.ctAdd:hover {
  background: #5f3490;
  color: #5f3490;
}
.ctAdd:active {
  background: #522b7f;
  color: #522b7f;
}

.ctaCon {
  /* background: #522B7F !important; */
  z-index: 1;
}

.ctDropDown {
  position: absolute;
  width: 100%;
  height: fit-content;
  background: #522b7f;
  z-index: 434589539453;
  top: 120%;
  right: 0;
  border-radius: 12px;
  overflow: hidden;
}

@media (max-width: 1441px) {
  .balance p {
    font-size: 32px;
  }
  .logoImg {
    width: 84px;
  }
  .ctImg {
    width: 24px;
    height: 24px;
  }
  .ctText {
    font-size: 12px;
  }
  .walletTag {
    padding: 1rem 0 0 0;
  }
  .countryTag {
    width: 36%;
  }
}

@media (max-width: 1367px) {
  .walBottom {
    padding: 0.6rem 0 0 0;
  }
  .walletTag {
    padding: 1.4rem 0 0 0;
  }
  .countryTag {
    width: 40%;
    padding: 4px 8px;
  }
  .walBottom p {
    font-size: 12px;
    margin: 1rem 0 0 0;
  }
  .balance p {
    font-size: 28px;
    margin: 0;
  }
}

@media (max-width: 1367px) {
}

/* =============================================================== SO THE MAIN COMPONENT STARTS FROM HERE */

.wallet {
  width: 100%;
  height: 100%;
}

.left {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding: 0 10px 0 0;
}

.leftWrap {
  width: 40%;
}

/* @media(min-width: 1370px){
  .leftWrap{
    width: 
  }
} */

.left::-webkit-scrollbar {
  width: 2px;
  background: none;
}
.left::-webkit-scrollbar-thumb {
  background: #40196d3f;
}

.top {
  width: 100%;
}

.quickActions {
  width: 50%;
  margin: auto;
}

.qWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2rem auto 2rem auto;
  cursor: pointer;
}

.qAction {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
}

.qIconWrap {
  width: 56px;
  height: 56px;
  border: 1px rgba(64, 25, 109, 0.1) solid;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px;
  transition: 0.2s ease-in-out;
}

.qAction:hover .qIconWrap {
  border: 1px rgba(64, 25, 109, 0.4) solid !important;
}

.actionCons {
  font-size: 30px;
  color: #40196d;
}

.form {
  width: 100%;
  margin: 1rem 0 0 0;
  /* background: green; */
}

.form form {
  width: 100%;
}

.labelPut {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 0 1rem 0;
}

.labelPut label {
  font-size: 14px;
  font-weight: 600;
  color: #1a1a1a;
  margin: 0 0 6px 0;
}

.input {
  width: 100%;
}

.inputs {
  margin: 8rem 0 4rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.inputs2 {
  margin: 4rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.inputs p {
  width: 70%;
}

.inputs2 {
  margin: 26px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.inputs2 p {
  width: 70%;
}

.input input {
  border: 1px solid #cacaca;
  border-radius: 6px;
  font-size: 14px;
  padding: 14px;
  width: 100%;
  transition: 0.3s ease-in-out;
}

.input input:focus {
  border: 1px solid #40196d;
}

.enterFive {
  margin: 1rem 0 5rem 0;
}

.select {
  width: 100%;
  position: relative;
}

.selectInput {
  cursor: pointer;
}

.selectInput input {
  cursor: pointer;
  /* background: yellow; */
}

.selectInput input:disabled {
  background: #fff;
  cursor: pointer;
}

.select .option {
  position: absolute;
  width: 100%;
  background: #fff;
}

.dropDown {
  position: absolute;
  right: 4%;
  top: 50%;
  /* transform: translateY( -50%) rotate(0deg); */
  transition: 0.2s ease all;
}

.select input {
  border: 1px solid #cacaca;
  border-radius: 6px;
  font-size: 14px;
  padding: 14px;
  width: 100%;
  transition: 0.3s ease-in-out;
}

.input select {
  border: 1px solid #cacaca;
  border-radius: 6px;
  font-size: 14px;
  padding: 10px;
  width: 100%;
  transition: 0.3s ease-in-out;
}

.option {
  /* border: 1px solid #cacaca; */
  border: none;
  border-radius: 6px;
  font-size: 14px;
  padding: 0 4px;
  width: 100%;
  transition: 0.3s ease-in-out;
  position: absolute;
  box-shadow: 0px 6px 10px #90909039;
  max-height: 120px;
  overflow-y: scroll;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  z-index: 1;
}

.option p {
  font-size: 14px;
  color: #717171;
  padding: 10px;
  border-radius: 4px 4px 0 0;
  border-bottom: 1px #90909042 solid;
  width: 100%;
  margin: 2px 0;
  cursor: pointer;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: 0.2s ease-in-out;
}

.option p:hover {
  background: #f5f5f5;
}

.option::-webkit-scrollbar {
  width: 2px;
  background: #9090907a;
}

.option::-webkit-scrollbar-thumb {
  background-color: #40196d;
}

.save {
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  padding: 16px;
  border-radius: 4px;
  border: none;
  background: #40196d;
  margin: 2rem 0 0 0;
  transition: 0.2s ease-in-out;
  cursor: pointer;
}

.save:hover {
  transform: translateY(-4px);
}

.save:active {
  transform: translateY(-1px);
}

.save:disabled {
  background: #909090;
}

.addWalletForm {
  margin: 0 0 0 0;
  color: #1a1a1a;
}

.awfTop {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin: 0.4rem 0 0 0;
}

.awfTop h2 {
  font-weight: 500;
  font-size: 16px;
  margin: 0.4rem 0 0.4rem 0;
}

.save2 {
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  padding: 16px;
  border-radius: 4px;
  border: none;
  background: #40196d;
  margin: 0.4rem 0 0 0;
  transition: 0.2s ease-in-out;
  cursor: pointer;
}

.save2:hover {
  transform: translateY(-4px);
}

.save2:active {
  transform: translateY(-1px);
}

.save2:disabled {
  background: #909090;
}

.lottieWrap {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lottieWrapDrop {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.setTrPin {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pinHead {
  text-align: center;
  margin: 16px 0 0 0;
  color: #1a1a1a;
}

.limit{
  width: 100%;
  padding: 0 1rem;
  background: #f5f5f5;
  border-radius: 10px;
  margin: 1rem 0;
}

.limit hr{
  border: none;
  height: 1px;
  background: #90909030
}

.dailyLimit{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dailyLimit p, .dailyLimit span{
  padding: 10px 0;
  font-size: 14px;
  color: #40196d;
}

@media (min-width: 1440px) {
  .leftWrap {
    width: 40%;
  }
}

@media (max-width: 737px) {
  .walletCard {
    padding: 16px;
  }
  .left {
    padding: 0;
    margin: 0 0 4rem 0;
  }
  .leftWrap {
    width: 100%;
  }
  .left::-webkit-scrollbar {
    display: none;
  }
}

/* {
  visible === true ? (
    <>
      {walletData.fiatWallets && walletData.fiatWallets.length > 0 ? (
        <CurrencyFormat
          value={walletData.fiatWallets[currNo].balance}
          displayType="text"
          style={{ fontFamily: "Bai Jamjuree" }}
          thousandSeparator={true}
        />
      ) : (
        "0000"
      )}
    </>
  ) : (
    "XXXXXXXXX"
  );
} */
