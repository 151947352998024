.tradeDetails{
    width: 100%;
    height: 100%;
}

.top{
    width: 100%;
    height: 10%;
    padding: 2rem 0;
}

.top h5{
    margin: 0
}

.wrapper{
    width: 100%;
    height: 90%;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    overflow-y: scroll ;
    padding: 0 10px 0 0 ;
}

.left{
    width: 40%;
    /* max-width: 430px; */
    height: 100%;
}
.left2{
    width: 40%;
    max-width: 430px;
    margin: 3rem auto 0 auto;
    height: 100%;
}

.wrapper::-webkit-scrollbar{
    background: none;
    width: 2px;
}

.wrapper::-webkit-scrollbar-thumb{
    background: #40196D;
}

.form {
    width: 100%;
    margin: 3rem auto 0 auto;
    /* background: green; */
}

.form form {
    width: 100%;
}

.labelPut {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 24px 0;
}

.labelPut2 {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 24px 0;
}

.labelPut label {
    font-size: 14px;
    font-weight: 600;
    color: #1a1a1a;
    margin: 0 0 6px 0;
}

.input {
    width: 100%;
}

.inputs {
    margin: 8rem 0 4rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.inputs p {
    width: 70%;
}

.inputs2 {
    margin: 26px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.inputs2 p {
    width: 70%;
}

.input input {
    border: 1px solid #cacaca;
    border-radius: 6px;
    font-size: 14px;
    padding: 14px;
    width: 100%;
    transition: .3s ease-in-out
}

.input input:focus {
    border: 1px solid #40196D;
}

.enterFive {
    margin: 1rem 0 5rem 0;
}

.select {
    width: 100%;
    position: relative;
}

.selectInput {
    cursor: pointer;
}

.selectInput input:disabled {
    background: #fff;
    cursor: pointer;
}

.select .option {
    position: absolute;
    width: 100%;
    background: #fff;
}

.dropDown {
    position: absolute;
    right: 4%;
    top: 50%;
    /* transform: translateY( -50%) rotate(0deg); */
    transition: .2s ease all;
}

.select input {
    border: 1px solid #cacaca;
    border-radius: 6px;
    font-size: 14px;
    padding: 14px;
    width: 100%;
    transition: .3s ease-in-out
}

.input select {
    border: 1px solid #cacaca;
    border-radius: 6px;
    font-size: 14px;
    padding: 10px;
    width: 100%;
    transition: .3s ease-in-out
}

.option {
    /* border: 1px solid #cacaca; */
    border: none;
    border-radius: 6px;
    font-size: 14px;
    width: 100%;
    max-height: 120px;
    transition: .3s ease-in-out;
    position: absolute;
    box-shadow: 0px 6px 20px #909090;
    height: 100px;
    overflow-y: scroll;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    z-index: 1;
}

.option p {
    font-size: 14px;
    color: #717171;
    padding: 12px;
    border-radius: 4px;
    border-bottom:1px #90909039 solid;
    width: 100%;
    margin: 2px 0;
    cursor: pointer;
    transition: .2s ease-in-out;
}

.option p:hover {
    background: #f5f5f5;
}

.option::-webkit-scrollbar {
    width: 2px;
    background: #9090907a;
}

.option::-webkit-scrollbar-thumb {
    background-color: #40196D;
}


.cardImg {
    border: 1px #cacaca solid;
    border-radius: 4px;
    width: 100%;
    aspect-ratio: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.cardImg p{
    color :#909090;
    position: absolute;
    top: 50%;

}

.cardImgTa {
    border: 1px #cacaca solid;
    border-radius: 4px;
    width: 100%;
    aspect-ratio: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.cardImgTa:focus{
    outline-color: #40196D;
}

.cardItem{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px;
    cursor: pointer;
    border-bottom:1px #F6F6F6 solid;
}

.cardItem:hover{
    background: #f5f5f5;
    transition: .2s ease-in-out; 
}

.cardItem_p{
    font-size: 14px;
    color: #717171;
    padding: 0px !important;
    cursor: pointer;
    transition: .2s ease-in-out; 
    border: none !important;
    margin: 0px !important;
}

.coinBtn{
    font-size: 12px;
    font-weight: 400;
    padding: 10px 16px;
    border-radius: 20px;
    border: none;
    transition: .2s ease-in-out;
    cursor: pointer;
}


.coinBtn_notgenerate{
    color: #40196D;
    background: #ECE8F0;
}

.coinBtn_generate{
    color: #3CAF47; 
    background: #22C55E1A;
}

.coinBtn:hover{
    transform: translateY(-4px);
}

.coinBtn:active{
    transform: translateY(-1px);
}


.cardItem img{
    width: 30px;
    height: 30px;
}

.right{
    width: 40%;
    max-width: 430px;
    height: 100%;
}

.rghTop{
    width: 100%;
    padding: 0 2rem;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
}

.rghTop p{
    font-size: 14px;
    font-weight: 400;
    color: #b6b6b6;
    text-align: center;
}

.rghTop span{
    font-size: 14px;
    font-weight: 400;
}

.barcode{
    width: 100%;
    margin: 30px auto 60px auto; 
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
}

.barcode h3{
    color: #40196D;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    
}

.bcTile{
    width: 60%; 
    aspect-ratio: 1;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bcTile img{
    width: 100%;
}

.walletAddr{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.walletAddr p{
    width: 70%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    color: #1a1a1a;
}



.waCopy{
    cursor: pointer;
    color: #1a1a1a;
    transition: .2s ease-in-out;
}

.waCopy:hover{
    color: #909090;
}
.waCopy:active{
    color: #1a1a1a;
}

.save {
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    padding: 16px;
    border-radius: 4px;
    border: none;
    background: #40196D;
    margin: 2rem 0 0 0;
    transition: .2s ease-in-out;
    cursor: pointer;
}

.save:hover {
    transform: translateY(-4px);
}

.save:active {
    transform: translateY(-1px);
}

.save:disabled{
    background: #909090;
}

.terms{
    width: 100%;
    margin: 2rem 0 0 0;
}

.termTop{
    width: 100%;
    background: #F5F3F7;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px;
}

.termTop p{
    font-size: 16px;
    font-weight: 600;
    color:#1a1a1a;
}

.termBottom{
    width: 100%;
    padding: 28px;
    text-align: justify;
    background: #FAF9FB;
}

.termBottom p{
    font-size: 14px;
    color: #1a1a1a;
    font-weight: 400;
    line-height: 27px; 
}

.uploadFile{
    background: none !important;
    /* border: none !important; */
    width:100% !important; 
    height: 100% !important;
    margin: auto !important;
    cursor: pointer;
    padding: 20% !important;
    opacity: 0;
}

.cardMaps{
    width:  100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.cardMaps p{
    padding: 1rem;
    font-size: 14px;
    color: #727272;
    margin : 0;
}

.oneUpload{
    display: flex;
    align-items: center;
    width: 100%;
    margin: auto;
    gap: 2rem;
}

.oneUpload:last-child{
    margin-bottom: 1rem;
}

.ouImg{
    width: 38px;
    aspect-ratio: 1.7;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
}

.ouProgress{
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.ouImg img{
    width: 100%;
}

.ouTop{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ouTop p{
    padding: 0;
}

.triCon{
    color: #FF4747;
    cursor: pointer;
    transition: .2s ease-in-out;
}

.triCon:hover {
    color: #e52525;
    cursor: pointer;
}

.triCon:active {
    color: #d11818;
    cursor: pointer;
}

.lottieWrap{
    width: 100%;
    height : 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
}

.lottieWrapDrop2{
    width: 60px;
    height : 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0%;
    top: 50%;
    transform: translateY(-50%);
    background: none;
}

.cryptal{
    width: 68%;
    margin: auto;
    text-align: center;
}

.cryptal img{
    width: 100px;
    margin: 1rem 0 .4rem 0;
}

.cryptalPee{
    color : #40196D !important;
    margin: .6rem 0 0 0 !important;
    cursor: pointer;
    text-decoration: underline;
}

.rate{
    width: 100%;
    background: #F5F3F7;
    border-radius: 4px;
    margin: 1rem  0 0 0;
    display: flex;
    padding: 0 1rem 1rem 1rem;
    flex-direction: column;
}

.rate p{
    font-size: 15px;
    font-weight: 500;
    color: #909090;
}

.rate span{
    font-weight: 700;
    color: #1a1a1a;
    font-size: 15px;
    font-style: normal;
}


@media(max-width:737px){
    .wrapper{
        width: 100%;
        flex-direction: column;
        height: fit-content;
        padding: 0 0 3rem 0;
    }
    .left{
        width: 100%;
        height: fit-content;
        overflow: hidden;
    }
    .left2{
        width: 100%;
        height: fit-content;
        overflow: hidden;
    }
    .form{
        width: 100%;
    }
    .right{
        width: 100%;
        max-width: 100%;
    }
}