.arcee{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    font-family: Syne, sans-serif;
}

.arceeCont{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    width: 30%;
    padding: 1.5rem;
    text-align: center;
}

.arceeCont h2{
    color: #40196D;
    font-size: 32px;
    font-weight: 600;
}

.arceeCont p{
    color: #727272;
    font-size: 14px;
}

@media(max-width: 737px){
    .arceeCont{
        width: 80%
    }
}