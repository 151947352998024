.login {
    width: 100%;
    height: 100vh;
    position: relative
}

.logo{
    width: 120px;
    position: absolute;
    top: 6%;
    left: 6%;
}

.logo img{
    width: 100%;
}

.log {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.left {
    width:30%;
    /* background: #f5f5f5; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.contLeft {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.contLeft h2 {
    font-size: 30px;
    line-height: 70px;
    color: #1a1a1a;
    white-space: nowrap;
    font-weight: 700;
}

.contLeft h2.title{
    margin-top: 20px;
}

.contLeft p {
    font-size: 14px;
    color: rgb(26, 26, 26, .9)
}
.contLeft p a{
    color: #40196D;
    font-size: 14px;
}

.pay{
    width: 100%;
    background: #40196D;
    color: #fff;
    padding: 12px;
    font-family: Syne, sans-serif;
    margin-top: 20px;
    border: none;
    border-radius: 4px ;
    transition: .2s ease-in-out;
    cursor :pointer;
}

.pay:hover{
    transform: translateY(-4px);
}

.pay:active{
    transform: translateY(-1px);
}

.pay:disabled {
    background: rgb(217, 209, 226, .5);
    color: #40196D;
}

.form {
    width: 100%;
}

.form form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin: 50px 0 0 0;
}

.inputs{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 0 70px 0;
}

.resendCode{
    font-size: 16px;
    text-align: center;
    margin:1rem 0 0 0 !important;
}

.resendCode span{
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
}

.resendCode span:hover{
    text-decoration: underline;
}

.lottieWrap{
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}


@media(max-width: 737px) {
    .left {
        width: 90%;
    }

    .formCont {
        width: 90%;
    }

    .right {
        height: 100%;
        overflow-y: scroll;
    }

    .mobileImg {
        display: block;
        position: absolute;
        top: 30px;
        left: 30%;
        transform: translateX(-90%);
        width: 100px;
    }

    .contLeft h2 {
        font-size: 16px;
        line-height: 22px;
        margin: 0 0 10px 0;
    }
    .contLeft p {
        margin: 0
    }

    .pay{
        width: 80%;
        margin: auto;
    }
}