.signup {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
}

.left {
  flex: 1;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.contImg {
  width: 74%;
  height: 78%;
}

.img {
  height: 78%;
  width: 100%;
  margin: 0 0 0 auto;
  overflow: hidden;
}

.img img {
  width: 100%;
  transform: translateY(-20px);
}

.cont {
  height: 26%;
  margin: 0 0 0 auto;
  width: 100%;
  text-align: center;
}

.cont h2 {
  font-size: 32px;
  color: #40196d;
  margin: 10px 0 0 0;
}

.cont p {
  font-size: 14px;
  color: #909090;
  line-height: 24px;
}

.logo {
  width: 120px;
  position: absolute;
  top: 8%;
  left: 26%;
  transform: translateX(-74%);
}

.logo img {
  width: 100%;
}

.right {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.formCont {
  width: 60%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.starter {
  width: 100%;
  text-align: center;
}

.starter h2 {
  font-size: 32px;
  color: #1a1a1a;
  margin: 0 0 8px 0;
}

.starter p {
  font-size: 14px;
  color: #909090;
  margin: 0 0 30px 0;
}

.form {
  width: 100%;
}

.form form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.labelPut {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.labelPut label {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  margin: 0 0 4px 0;
}

.required {
  color: #ff0000;
}

.optional {
  color: #909090;
}

.input {
  width: 100%;
  position: relative;
}

.visSwitch {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.input input {
  width: 100%;
  font-size: 12px;
  color: #909090;
  border-radius: 4px;
  padding: 14px 10px;
  border: 1px rgba(51, 51, 51, 0.3) solid;
}

.input input:focus {
  border-color: #40196d;
}

.input input[type="checkbox"]:checked {
  background-color: #40196d;
}

.input select {
  width: 100%;
  padding: 14px;
  border-radius: 4px;
  border: 1px #909090 solid; 
}

.input select:focus {
  border: 1px #40196d solid;
}

.select {
  width: 100%;
  position: relative;
} 

.selectInput {
  cursor: pointer;
} 

.selectInput input {
  width: 100%;
  font-size: 12px;
  color: #909090;
  border-radius: 4px;
  padding: 16px 10px;
  border: 1px rgba(51, 51, 51, 0.3) solid;
} 

.selectInput input:disabled {
  background: #fff;
  cursor: pointer;
}

.select .option {
  position: absolute;
  width: 100%;
  background: #fff;
}

.dropDown {
  position: absolute;
  right: 4%;
  top: 50%;
  /* transform: translateY( -50%) rotate(0deg); */
  transition: 0.2s ease all;
}

.selectActive {
  border: 1px solid #40196d;
  border-radius: 6px;
  font-size: 14px;
  padding: 14px;
  width: 100%;
  transition: 0.3s ease-in-out;
}

.selectDormant {
  border: 1px solid #cacaca;
  border-radius: 6px;
  font-size: 14px;
  padding: 14px;
  width: 100%;
  transition: 0.3s ease-in-out;
}

.input select {
  border: 1px solid #cacaca;
  border-radius: 6px;
  font-size: 14px;
  padding: 10px;
  width: 100%;
  transition: 0.3s ease-in-out;
}

.option {
  /* border: 1px solid #cacaca; */
  border: none;
  border-radius: 6px;
  font-size: 14px;
  width: 100%;
  transition: 0.3s ease-in-out;
  position: absolute;
  box-shadow: 0px 6px 20px #909090;
  height: 100px;
  overflow-y: scroll;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  z-index: 1;
}

.option p {
  font-size: 14px;
  color: #717171;
  padding: 12px;
  border-radius: 4px;
  width: 100%;
  margin: 2px 0;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.option p:hover {
  background: #f5f5f5;
}

.option::-webkit-scrollbar {
  width: 2px;
  background: #9090907a;
}

.option::-webkit-scrollbar-thumb {
  background-color: #40196d;
}

.button {
  width: 100%;
  height: 52px;
  max-height: 50px;
  padding: 14px;
  font-size: 12px;
  color: #fff;
  background: #40196d;
  border-radius: 4px;
  border: none;
  transition: 0.2s ease-in-out;
  cursor: pointer;
}

.button:hover {
  transform: translateY(-3px);
  box-shadow: 0px 4px 4px #0000002b;
}

.button:active {
  transform: translateY(-1px);
  box-shadow: 0px 1px 4px #0000002b;
}

.terms {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 14px;
}

.terms p {
  color: #1a1a1a;
  font-weight: 400;
  font-size: 14px;
}

.terms p a {
  color: #40196d;
  font-weight: 400;
  font-size: 14px;
}

.alternates {
  width: 100%;
  padding: 10px;
  margin: 10px 0 0 0;
}

.googleFace {
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin: 0 0 1rem 0;
}

.googleFace p {
  font-size: 14px;
  color: #909090;
}

.register {
  font-size: 14px;
  text-align: center;
}

.register a {
  color: #40196d;
  font-size: 14px;
}

.logoCons {
  height: 40px;
  width: 40px;
  background: #fef7fe;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
}

.logoCons:hover {
  box-shadow: 0px 0px 5px #90909048;
}

.logoCons:active {
  box-shadow: 0px 0px 5px #90909072;
}

.logoImg{
  width: 100%;
  display: none;
  align-items: center;
  justify-content: center;
  margin: 0 0 4rem 0;
}

.logoImg img{
  width: 14%;
}

/* ================================= FORMIK AND YUP */

.pError {
  color: red;
  font-size: 10px;
  font-weight: 300;
}

.error {
  border: 1px red solid !important;
  /* padding: .8rem 1rem;
    border-radius: 4px; */
}

.notError {
  border: 1px rgba(51, 51, 51, 0.3) solid !important;
  padding: 0.8rem 1rem;
}

.notError:focus {
  border: 1px rgba(51, 51, 51, 0.3) solid;
  outline: none;
}

.notError input:focus {
  border: 1px rgba(51, 51, 51, 0.3) solid;
  outline: none;
}

.slideCators {
  /* background: #40106D; */
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.dot {
  height: 6px;
  width: 6px;
  border-radius: 30px;
  background: #eae9ed;
  transition: 0.2s ease-in-out;
}

.dotActive {
  height: 6px;
  width: 24px;
  border-radius: 30px;
  background: #40106d;
  transition: 0.2s ease-in-out;
}

.mobileImg {
  display: none;
}

.indiHr {
  width: 100%;
  border: none;
  border-top: 2px rgba(217, 209, 226, 1) solid;
}

.indiHrHi {
  width: 100%;
  border: none;
  border-top: 2px #40106d solid;
}

.tickCircle {
  margin: 0;
  font-size: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(217, 209, 226, 1);
  /* border: 0.5px rgba(217, 209, 226, 1) solid; */
  border-radius: 200px;
}

.tickCircleLined {
  margin: 0;
  font-size: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(217, 209, 226, 1);
  border: 0.5px #40196d solid;
  border-radius: 200px;
}

.tickCircleDone {
  margin: 0;
  font-size: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #40196d;
  /* border: .5px #40196D solid; */
  border-radius: 200px;
}

.indicator {
  /* background: yellow; */
  width: 50%;
  margin: 1rem auto 1rem auto;

  display: flex;
  align-items: center;
  justify-content: center;
}

.first {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

}

.lottieWrap{
  width: 100%;
  height: 100%;
  max-height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.second {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.button:disabled {
  background: #909090;
}

@media (min-width: 1370px) {
  .input input {
    width: 100%;
    font-size: 12px;
    color: #909090;
    border-radius: 4px;
    padding: 18px 14px;
    border: 1px #909090 solid;
  }
  .button {
    padding: 16px 10px;
  }
  .starter p {
    margin: 0 0 2rem 0;
  }
  .form form {
    gap: 1.6rem;
  }
}

@media (max-width: 737px) {
  .left {
    display: none;
  }
  .formCont {
    width: 90%;
    margin: 3rem 0 0 0;
  }
  .right {
    height: 100%;
    overflow-y: scroll;
  }
  .mobileImg {
    display: none;
    position: absolute;
    top: 20px;
    left: 5%;
    width: 70px;
  }
  .starter h2 {
    font-size: 16px;
  }
  .logoImg{
    display: flex;
  }
}
