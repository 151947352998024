.main{
    width: 100%;
    height: 100%;
    margin: 0 0 100px 0;
}

.mainContainer{
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    padding: 0 14px 0 0;
    /* background: green; */
}

.mainContainer::-webkit-scrollbar{
    width: 2px;
    background: rgba(26, 26, 26, 0.1);
    border-radius: 10px;
}
.mainContainer::-webkit-scrollbar-thumb{
    background: #40196D;
}


.welcome{
    margin: 0 0 22px 0;
    font-size: 16px;
}

.bottom{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 2rem;
    /* background: skyblue; */
}

.left{
    width: 40%;
}


/* .country{
    border-radius: 8px;
    overflow: hidden;
    width: 32px;
    height: 20px;
    background: green;
    display: flex;
    align-items: center;
    justify-content: center;
} */

.moneyCards{
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 0 0 2rem 0;
    width: 100%;
}

.moneyCard{
    padding: 28px ;
    background: #FCF8FF;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    width: 50%;
}

.moneyCard h2{
    font-family: Bai Jamjuree !important;
    color: #40196D;
    font-size: 36px;
}

.moneyCard p{
    font-size: 12px;
}

.mcLeft {
    width: 82px;
    height: 82px;
    /* background: rgba(0, 0, 0, 0.16); */
    position: relative;
}

.mcLeft:after{
    content:"";
    background-image:url('../../assets/images/float.svg');
    width: 100px;
    height: 100px;
    position: absolute;
    top: 50%;
    right: -42%;
    transform: translateY( -50%);
    z-index: -1
}

.balance{
    font-size: 16px;
    font-weight: 600;
    color: rgba(26, 26, 26, 0.9);
}

.percentage{
    display: flex;
    align-items: flex-end;
    color: green;
    background: #fff;
}

.percentage p{
    font-family: Poppins, sans-serif;
    color: green; 
}

.wallet{
    display: flex;
    align-items: flex-end;
    gap: 12px;
} 

.wallet p{
    font-size: 12px;
}

.mcRight{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    gap : 1rem
}

.quickActions{
    width: 100%;
    margin: 3rem 0 0 0;
}

.qWrapper{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 2rem 0;
    gap: 30px;
}

.qAction{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 14px;
    cursor: pointer;
}

.qAction:hover{
    color: #40196D;
}

.qIconWrap{
    width: 70px;
    height: 70px;
    border: 1px rgba(64, 25, 109, 0.1) solid ;
    border-radius: 50pc;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px;
    background: #40196D;
    color: #fff !important;
    transition: .2s ease-in-out;
}

.qAction:hover .qIconWrap{
    background: #4e1f84;
    border: 1px #40196D solid ;
}

.title{
    font-weight: 600;
    font-size: 15px;
}
.actionCons{
    font-size: 30px;
    color: #40196D;
}

.stats{
    width: 80%;
}

.statsChart{
    width: 100%;
}

.statsChart img{
    width: 100%;
}

.right{
    width: 50%;
    height: 100%;
}

.virtualCard{
    margin: 0 0 2rem 0;
    padding: 0 10px 0 0;
}

.recentTransactions{
    height: 100%;
    padding: 0 10px 0 0;
    /* background: orange; */
}

.recentTransactions h4{
    height: 4%;
}

.transaction {
    height: 96%;
    padding: 0 0 5rem 0;
    overflow-y: scroll;
}

.transaction::-webkit-scrollbar{
    display: none;
}

.lottieWrap{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3rem 0 0 0;
}


.oneSaction{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
}

.transaction{
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
}

.transaction::-webkit-scrollbar{
    width: 2px;
}
.transaction::-webkit-scrollbar-thumb

.triCon{
    display: flex;
    align-items: center;
    justify-content: center;
}

.triConOn{
    padding: 12px;
    height: 42px;
    width: 42px;
    color: purple;
    background: rgba(143, 146, 161, 0.08);
    border-radius: 8px;
}

.triTitleDate{ 
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 12px;
    flex: 1;
} 

.triTitleDate p{
    font-size: 14px;
    color: rgba(26, 26, 26, 1);
    font-weight: 500;
}

.triTitleDate span{
    font-size: 12px;
    color: rgba(26, 26, 26, .6);
    font-weight: 400;
}

.triAmountStatus{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap:6px;
}

.triAmountStatus p{
    font-size: 12px;
    font-weight: 500;
    color: rgba(26, 26, 26, 1);
    font-size: 12px;
    font-weight: 500;
    border-radius: 6px;
    padding: 6px 10px;
    text-align: center;
    min-width:80px;
    max-width: fit-content;
}

.triAmountStatus span {
    color: #FACC15;
    font-size: 13px;
    font-weight: 600;
}

.success {
    /* font-family: Manrope, sans-serif; */
    background: #F6FDF9;
    color: #22C55E !important;
    font-size: 12px;
    font-weight: 500;
    border-radius: 6px;
    padding: 6px 10px;
    text-align: center;
    min-width:80px;
    max-width: fit-content;
}

.pending{
    font-family: Manrope, sans-serif;
    background: #fffbd2;
    color: #ff9100 !important;
    font-size: 12px;
    font-weight: 500;
    border-radius: 6px;
    padding: 10px 20px;
    text-align: center;
    max-width: fit-content;
    min-width: 100px;
}

.failed {
    /* font-family: Manrope, sans-serif; */
    background: #ffeeee;
    color: #ff0e0e !important;
    font-size: 12px;
    font-weight: 500;
    border-radius: 6px;
    padding: 6px 10px;
    text-align: center;
    min-width:80px;
    max-width: fit-content;
}

/* ========================================================================================= UNVERIFIED KYC DASHBOARD */

.verifyPrompt {
    width: 100%;
    background: rgba(64, 25, 109, 0.05);
    padding:  2rem ;
    border-radius: 6px;
    margin: 0 0 4rem 0;
    display: flex;
    align-items: flex-start;
    gap: 1rem;
}

.veriCont{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
}

.veriCont h4{
    width: 60%;
    font-weight: 500;
    font-size: 18px;
    margin : 0 0 10px 0;
}

.veriCont p{
    width: 60%;
    font-weight: 500;
    font-size: 14px;
    margin : 0 0 10px 0;
    color:rgba(26, 26, 26, .6)
}

.verifyPrompt button{
    padding: 12px 42px;
    font-family: Syne, sans-serif;
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    background: #40196D;
    border: none;
    border-radius: 8px;
    transition: .2s ease-in-out;
    cursor: pointer;
}
.verifyPrompt button:hover{
    background: #3b1764;
    transform: translateY(-4px);
}

.verifyPrompt button:active{
    background: #331457;
    transform: translateY(-1px);
}


.setYourPin{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.setYourPin h2{
    margin: 0 0 12px 0;
}

.save {
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  padding: 16px;
  border-radius: 4px;
  border: none;
  background: #40196d;
  margin: 2rem 0 0 0;
  transition: 0.2s ease-in-out;
  cursor: pointer;
}

.save:hover {
  transform: translateY(-4px);
}

.save:active {
  transform: translateY(-1px);
}

.save:disabled{
    background: #909090;
}

.tableResponsive{
    border: 2px solid rgba(64, 25, 109, 0.05);
    border-radius: 10px;
    width: 100%;
    padding: 2rem;
    margin: 0 0 14px 0;
    
}

.tableResponsive h4 {
    font-size: 16px;
    font-weight: 500;
    margin: 0 0 14px 0;
}

table{
    width: 100%;
}

table thead{
    width: 100%;
}

table thead tr th{
    /* flex: 1; */
    padding: 10px ;
    text-align: left;
    color: #40196D;
    font-weight: 700;
    font-size: 12px;
}

td{
    font-size: 12px;
    padding: 10px ;
    width: 16.7%;
    color: rgba(26, 26, 26, 0.6);
}

.stats{
    width: 100%;
}

.stats h2{
    font-size: 16px;
    margin: 0 0 2rem 0
}

@media(min-width: 1367px) {
    .left{
        width: 44%;
    }
    .moneyCard {
        width: 50%;
        padding: 28px;
        gap: 12px;
    }
    .moneyCard h2{
        font-size: 40px;
    }
    .moneyCard p{
        font-size: 14px;
        white-space: nowrap;
    }
    .mcLeft{
        width: 34%;
    }
    .mcLeft::after{
        display: none
    }
    .mcRight{
        width: 60%;
    }
    .mcRight p{
        font-size: 14px;
    }
}
@media(max-width: 1367px) {
    .moneyCard {
        width: 50%;
        padding: .8rem;
        gap: 12px;
    }
    .mcLeft{
        width: 70px;
        height: 70px;
        aspect-ratio: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .mcLeft:after {
        content: "";
        background-image: url('../../assets/images/float.svg') ;
        background-size: contain;
        width: 140%;
        height: 140%;
        position: absolute;
        top: 60%;
        right: -62%;
        transform: translateY(-50%);
        z-index: -1;
        display: none;
    }
    .balance {
        font-size: 12px;
        white-space: nowrap;
    }

    .wallet img{
        width: 30px;
    }
    .quickActions{
        margin: 1rem 0 0rem 0;
    }
}

@media(max-width: 737px) {

    .mainContainer{
        overflow: hidden;
        padding: 0;
        height: fit-content;
        padding: 0 0 3rem 0;
    }
    .bottom{
        flex-direction: column;
        margin: 0;
        width: 100%;
    }
    .left{
        width: 100%;
    }
    .moneyCards{
        width: 100%;
        flex-direction: column;
    }
    .moneyCard{
        width: 90%;
        gap: 2rem;
    }
    .right{
        width :100%
    }
    .success, .pending, .failed {
        font-size: 10px;
    }
    .qWrapper{
        justify-content: space-evenly;
    }
    .qIconWrap{
        width: 40px;
        height: 40px;
        padding: 10px;
    }
    .title{
        font-size: 12px;
        font-weight: 600;
    }
}