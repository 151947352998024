.signup {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
}


.modalImgWrap{
    width: 100%;
    display: flex;
    align-items: center;
    margin : 0 0 20px 0
}

.modalImg {
    width: 50px;
    height: 50px;
    margin: auto;

}

.lottieWrap{
    width : 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 52px;
}






.left {
    flex: 1;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.contImg {
    width: 74%;
    height: 78%;
}

.img {
    height: 78%;
    width: 100%;
    margin: 0 0 0 auto;
    overflow: hidden;
}

.img img {
    width: 100%;
    transform: translateY(-20px);
}

.cont {
    height: 26%;
    margin: 0 0 0 auto;
    width: 100%;
    text-align: center;
} 

.cont h2 {
    font-size: 32px;
    color: #40196D;
    margin: 10px 0 0 0;
}

.cont p {
    font-size: 14px;
    color: #909090;
    line-height: 24px;
}

.logo {
    width: 120px;
    position: absolute;
    top: 8%;
    left: 26%;
    transform: translateX(-74%);

}

.logo img {
    width: 100%;
}

.right {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.formCont {
    width: 60%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto 0;
}

.starter {
    width: 100%;
    text-align: center;
}

.starter h2 {
    font-size: 32px;
    color: #1A1A1A;
    margin: 0 0 8px 0;
}

.starter p {
    font-size: 14px;
    color: #909090;
    margin: 0 0 40px 0;
}

.form {
    width: 100%;
}

.form form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.labelPut {
    width: 100%;
    display: flex;
    flex-direction: column;

}

.labelPut label {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    margin: 0 0 4px 0;
}

.required {
    color: #ff0000;
}

.optional {
    color: #909090;
}

.input {
    width: 100%;
    position: relative;
}

.visSwitch {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%)
}

.input input {
    width: 100%;
    font-size: 12px;
    color: #909090;
    border-radius: 4px;
    padding: 14px 10px;
    border: 1px #909090 solid;
    /* max-height: 36px; */
}

.input input:focus {
    border-color: #40196D;
}

.input input[type="checkbox"]:checked {
    background-color: #40196D;
}

.button {
    width: 100%;
    padding: 14px;
    font-size: 12px;
    color: #fff;
    background: #40196D;
    border-radius: 4px;
    border: none;
    transition: .2s ease-in-out;
    cursor: pointer;
    max-height: 52px;
}

.button:hover {
    transform: translateY(-3px);
    box-shadow: 0px 4px 4px #0000002b;
}

.button:active {
    transform: translateY(-1px);
    box-shadow: 0px 1px 4px #0000002b;
}

.button:disabled{
    background: #909090;
}

.terms {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 14px;
}

.terms p {
    color: #1a1a1a;
    font-weight: 400;
    font-size: 14px;

}

.terms p a {
    color: #40196D;
    font-weight: 400;
    font-size: 14px;
}

.alternates {
    width: 100%;
    padding: 10px;
    margin: 10px 0 0 0
}

.googleFace {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin: 0 0 1rem 0;
}

.googleFace p {
    font-size: 14px;
    color: #909090;
}

.register {
    font-size: 14px;
    text-align: center;
}

.register a {
    color: #40196D;
    font-size: 14px;
}

.logoCons {
    height: 40px;
    width: 40px;
    background: #FEF7FE;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
}


.logoImg{
  width: 100%;
  display: none;
  align-items: center;
  justify-content: center;
  margin: 0 0 4rem 0;
}

.logoImg img{
  width: 14%;
}

.fgtPwd{
    color: #909090;
    font-size: 12px;
    text-align: right;
    cursor: pointer; 
    width: fit-content;
}
.fgtPwd:hover{
    color: #40196D;
    text-decoration: underline;
}

.logoCons:hover {
    box-shadow: 0px 0px 5px #90909048
}

.logoCons:active {
    box-shadow: 0px 0px 5px #90909072
}

/* ================================= FORMIK AND YUP */

.pError {
    color: red !important;
    font-size: 10px !important ;
    font-weight: 300 !important ;
    text-align: left !important; 
    margin: 0 !important ; 
}

.error {
    border: 1px red solid !important;
    /* padding: .8rem 1rem;
    border-radius: 4px; */
}

.notError {
    border: 1px rgba(51, 51, 51, 0.3) solid !important;
    padding: .8rem 1rem;
}

.notError:focus {
    border: 1px rgba(51, 51, 51, 0.3) solid;
    outline: none;
}

.notError input:focus {
    border: 1px rgba(51, 51, 51, 0.3) solid;
    outline: none;
}
/* ========================================================================= MY KEYFRAMES */

@media(min-width: 1370px) {
    .input input {
        width: 100%;
        font-size: 12px;
        color: #909090;
        border-radius: 4px;
        padding: 16px 10px;
        border: 1px #909090 solid;
    }

    .button {
        padding: 16px 10px;
    }

    .starter p {
        margin: 0 0 3rem 0;
    }

    .form form {
        gap: 1.6rem
    }
}


/* ========================================================================= MY KEYFRAMES */

@keyframes scale {
    from{
        transform: scale(0);
    }
    from{
        transform: scale(1);
    } 
}


    /* ========================================================================= MY MEDIA QUERIES */


@media(max-width: 737px) {
    .left {
        display: none;
    }
    .formCont {
        width: 90%;
    }
    .right {
        height: 100%;
        overflow-y: scroll;
    }
    .mobileImg {
        display: block;
        position: absolute;
        top: 30px;
        left: 30%;
        transform: translateX(-90%);
        width: 100px;
    }
    .starter h2 {
        font-size: 16px;
    }
    .logoImg{
        display: flex;
    }
}