.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.9);
  z-index: 1;
  display: none;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(2px);
  animation: 0.4s fade-out ease alternate;
}

.modalFade {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: 0.4s fade ease-out alternate;
}

.popUp {
  width: fit-content;
  min-width: 30%;
  padding: 1.5rem;
  background: #fff;
  border-radius: 10px;
  position: relative;
  transition: 0.3s ease-in-out;
  animation: 0.4s fade-down ease alternate;
}

.popUpModalChild {
  width: fit-content;
  min-width: 24%;
  padding: 1.5rem;
  background: #fff;
  border-radius: 10px;
  position: relative;
  transition: 0.3s ease-in-out;
  animation: 0.4s fade-down ease alternate;
}

.popUp2 {
  /* width: 30%; */
  padding: 1.5rem;
  background: #40196d;
  border-radius: 10px;
  position: relative;
  transition: 0.3s ease-in-out;
  animation: 0.4s fade-down ease alternate;
  text-align: center;
  color: #fff;
  overflow: hidden;
}

.popUp h4 {
  font-size: 18px;
  text-align: center;
  margin: 0 0 8px 0;
  font-weight: 500;
}

.popUp p {
  font-size: 14px;
  margin: 0 0 1.6rem 0;
  font-weight: 400;
  color: #909090;
  text-align: center;
}

.closeModalConWrap {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
   justify-items: center;
}

.closeModalConWrap p{
  color:#000000;
  font-size: 16px;
  font-weight: 600;
  flex: 1 auto;
  margin-bottom: 0px;
}

.closeModalCon {
  transition: 0.2s ease-in-out;
  cursor: pointer;
  z-index: 1;
  position: relative;
}

.closeModalCon:hover {
  transform: scale(1.1);
}

.closeModalCon:active {
  transform: scale(1);
}

.closeModalCon2 {
  font-size: 30px;
  color: #ffffff;
  position: absolute;
  right: 10px;
  top: 10px;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  transform: rotate(45deg);
}
.closeModalCon2:hover {
  transform: rotate(135deg) scale(1.1);
}

.closeModalCon2:active {
  transform: rotate(135deg) scale(1);
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-down {
  from {
    opacity: 0;
    transform: translateY(-200px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@media (max-width: 737px) {
  .popUp {
    width: 90%;
  }
}
