.exchangeDiv{
    width: 40%;
    margin-top: 30px;
}

label{
    font-size: 14px;
    font-weight: 600;
    color: #1a1a1a;
}

.select{
  margin-top: 10px;
  position: relative;
}

.selectInput {
    cursor: pointer;
}

.selectInput input:disabled {
    background: #fff;
    cursor: pointer;
}

.selectInput .walletDiv{
    border: 1px solid #909090;
    border-radius: 6px;
    font-size: 14px;
    padding: 14px 14px;
    width: 100%;
    transition: .3s ease-in-out;
    display: flex;
    align-items: center;
    gap: 10px;
    min-height: 50px;
}

.walletTitle{
    color: #909090;
    font-size: 14px;
}

.select input {
    border: 1px solid #909090;
    border-radius: 6px;
    font-size: 14px;
    padding: 14px;
    width: 100%;
    transition: .3s ease-in-out;
}

.select .option {
    position: absolute;
    width: 100%;
    background: #fff;
}

.dropDown {
    position: absolute;
    right: 4%;
    top: 50%;
    /* transform: translateY(-50%) rotate(0deg); */
    transition: .2s ease all;
}

.option {
    /* border: 1px solid #cacaca; */
    border: none;
    border-radius: 6px;
    font-size: 14px;
    width: 100%;
    max-height: 120px;
    transition: .3s ease-in-out;
    position: absolute;
    box-shadow: 0px 6px 20px #909090;
    height: 100px;
    overflow-y: scroll;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    z-index: 1;
}

.option p {
    font-size: 14px;
    color: #717171;
    padding: 12px;
    border-radius: 4px;
    border-bottom:1px #90909039 solid;
    width: 100%;
    margin: 2px 0;
    cursor: pointer;
    transition: .2s ease-in-out;
}

.option p:hover {
    background: #f5f5f5;
}

.option::-webkit-scrollbar {
    width: 2px;
    background: #9090907a;
}

.option::-webkit-scrollbar-thumb {
    background-color: #40196D;
}

.input input {
    margin-top: 10px;
    border: 1px solid #909090;
    border-radius: 6px;
    font-size: 14px;
    padding: 14px;
    width: 100%;
    transition: .3s ease-in-out;
    min-height: 50px;
}

.input input:focus {
    border: 1px solid #40196D;
}


.save {
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    padding: 16px;
    border-radius: 4px;
    border: none;
    background: #40196D;
    transition: .2s ease-in-out;
    cursor: pointer;
}

.save:hover {
    transform: translateY(-4px);
}

.save:active {
    transform: translateY(-1px);
}

.save:disabled{
    background: #909090;
}

.info_div{
    margin-top: 12px;
    display: flex;
    gap: 5px;
    align-items: center;
  }

  .info_div p{
    color: #5F177F;
    font-size: 14px;
  }

  .setTrPin {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .pinHead {
    text-align: center;
    margin: 16px 0 0 0;
    color: #1a1a1a;
  }

  .inputs2 {
    margin: 5px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
  .inputs2 p {
    width: 70%;
  }

  .animate_spin{
    animation: spin 1s linear infinite;
}

@keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  


@media(max-width:737px){
    .exchangeDiv{
        width: 100%;
    }
}