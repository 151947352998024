.transaction{
    width : 100%;
    height: 100%;
    overflow-y: scroll;
}

.transaction::-webkit-scrollbar{
    display: none;
}

.top{
    width: 100%;
    padding: 0 0 2rem 0;
}

.top h2{
    font-size: 14px;
    color: #1a1a1a;
    font-weight: 600;
}

.switchWrap{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.switchText{
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.switchText p{
    font-size: 14px;
    font-weight: 600;
    color: #B1B0AE;
    padding: 8px 70px;
    cursor: pointer;
}

.switchBar{
    width: 60%;
    max-width: 644px;
    height: 4px;
    background: #EDEEEF;
    position: relative;
    transition: .2s ease-in-out;
}

.bar{
    position: absolute;
    height: 100%;
    width: 50%;
    background: #40196D;
    left: 0%;
    transition: .2s ease-in-out;
}

.searchFilter{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 52px;
    border-bottom: 1px #EDF2F7 solid;
    margin: 0 0 30px 0;
}

.searchPut{
    border: 1px #E2E8F0 solid ;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 14px; 
    padding: 10px 18px;
    width: 296px;
}

.searchPut input{
    border: none;
    width: 100%;
}

.searchCon{
    cursor: pointer;
}

.filter{
    border: 1px #E2E8F0 solid ;
    border-radius: 8px;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #727272;    
    padding: 10px 18px;
    position: relative;
    cursor: pointer;
}

.filterDD{
    background: white;
    border-radius: 6px;
    position: absolute;
    right: 0;
    top: 110%;
    width: 120%;
    box-shadow: 0px 0px 5px #00000030;
    overflow: hidden;
}

.fDDP{
    width: 100%;
    text-align: left;
    padding: 10px;
    transition: .2s ease-in-out;
}

.fDDP:hover{
    width: 100%;
    text-align: left;
    padding: 10px;
    background: #90909010;
}

.topBtns{
    border: 2px #40196D solid;
    border-radius: 10px;
    margin: 0 4px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    width: 30%;
    cursor: pointer;
    transition: .2s ease-in-out
}

.topBtns p{
    font-size: 12px;
    font-weight: 600;
    padding : 6px 0 0 0;
    transition: .2s ease-in-out
}

.topCons{
    background: #FAF9FB;
    height: 40px;
    width :40px;
    border-radius: 50%;
    padding: 10px;
    font-size: 10px !important;
    transition: .2s ease-in-out
}

.cashTabs{
    width: 100%;
    margin: 1.2rem 0 0 0;
}

.cashTabs ul{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    list-style: none;
    position: relative;
}

.cashTabs ul::after{
    content: "";
    position: absolute;
    width: 100%;
    border-bottom: 1px #90909039 solid;
    bottom: 0;
}

.cashTabs ul li{
    width: 10%;
    padding :10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor:pointer;
    transition: .2s ease-in-out;
    white-space: nowrap;
}
.cashTabs ul li:hover{
    background: #90909039;
}

.cashTabs ul li p{
    font-size: 12px;
}
/* ============================================================TABLE SYLES */

.table{
    width: 100%;
    font-family: Manrope, sans-serif;
    height: 380px;
    background: #fff;
    overflow-y: scroll;
}

.table::-webkit-scrollbar{
    width: 2px;
    background: #9090903a;
}

.table::-webkit-scrollbar-thumb{
    background: #40196D;
    border-radius: 6px;
}

.table table{
    width: 100%;
    border-spacing: 0px
}

.table table thead{
    width: 100%;
}


.tableHead h5{ 
    font-family: Manrope, sans-serif;
    font-size: 14px;
    color: #1a1a1a;
    font-weight: 800;
    margin: 0;
}

.table table thead tr th{
    /* width: 20%; */
    padding: 8px 8px 20px 8px;
}

.user{
    max-width: 4% !important;
    padding: 0;
    color: #fff;
}

.image{
    width: 38px;
    height: 38px;
    border-radius: 50%;
    margin: 0 0 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.image img{
    height: 100%;
}

tbody tr td{
    padding: 16px 8px
}

.tableHead{
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    gap: 14px;
}

.arrowCon{
    font-size: 14px;
    color: #1a1a1a;
}

.name{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4px;
}

.name h6{
    font-family: Manrope, sans-serif;
    font-size: 14px;
    color: #1a1a1a;
    font-weight: 600;
}

.name p{
    font-family: Manrope, sans-serif;
    font-size: 12px;
    color: #727272;
    font-weight: 500;
}

.date{
    font-family: Manrope, sans-serif;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4px;
}

.date h6{
    font-family: Manrope, sans-serif;
    font-size: 14px;
    color: #1a1a1a;
    font-weight: 500;
}

.date p{
    font-family: Manrope, sans-serif;
    font-size: 12px;
    color: #727272;
    font-weight: 500;
}

.invoice{
    font-family: Manrope, sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #1a1a1a;
    width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.copyCon{
    color: #909090 !important;
    width: 26px;
    cursor: pointer;
    transition: .2s ease-in-out;
}

.copyCon:hover{
    color: #40196D !important;
}

.copyCon:active{
    color: #40196D40 !important;
}

.amount{
    font-family: Manrope, sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: #3e3e3e;
}
 
.success{
    font-family: Manrope, sans-serif;
    background: #F6FDF9;
    color: #22C55E;
    font-size: 12px;
    font-weight: 500;
    border-radius: 6px;
    padding: 10px 20px;
    text-align: center;
    max-width: fit-content;
    min-width: 100px;
    border: none;
}

.pending{
    font-family: Manrope, sans-serif;
    background: #fffbd2;
    color: #ff9100;
    font-size: 12px;
    font-weight: 500;
    border-radius: 6px;
    padding: 10px 20px;
    text-align: center;
    max-width: fit-content;
    min-width: 100px;
}


.failed {
    /* font-family: Manrope, sans-serif; */
    background: #FFF7F5;
    color: #ff0e0e !important;
    font-size: 12px;
    font-weight: 500;
    border-radius: 6px;
    padding: 10px 20px;
    text-align: center;
    max-width: fit-content;
    min-width: 100px;
}

.emptyCont{
    width: 100%;
    height: 300px;
}

.transList{
    display: none;
}

.lottieWrap{
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fullRow{
    padding: 20px 0 0 0px ;
    transition: .2s ease-in-out;
    cursor: pointer;
}
.fullRow:hover{
    background: #90909039;
}
.fullRow:active{
    background: #90909050;
}


/* ================================ STYLES FOR THE TRANSACTIONS PANEL BY THE RIGHT */

.transBar{
    width:380px;
    height: 100vh;
    background: #fff;
    /* background: rgb(183, 183, 183); */
    box-shadow: 0px 0px 10px #90909039;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .2s ease-in-out;
}

.transWrap{
    width: 100%;
    height: 100%;
}

.twTop{
    width: 100%;
    height: 7%;
    min-height: 60px;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #90909039;
}

.twBottom{
    width: 100%;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.25rem;
    overflow-y : scroll;
}

.twbWrap{
    width: 100%;
    height: fit-content;
    /* background: skyblue; */
    display: flex;
    gap: 2rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 8rem 0 0 0;
}

.tdTop{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.tdtImage{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 0 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tdtImage img{
    height: 104%
}

.tdTop h3{
    font-size: 28px;
    font-weight: 600;
    font-family: Poppins, sans-serif;
    color: #3c3c3c;
}

.tdTop p{
    font-size: 14px;
    color: #727272;
    margin: 0px 0 0 0;
}

.tdBottom{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.6rem; 
}

.tdItemWrap{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.labelItem{
    display: flex;
    align-items: center;
    gap: 10px;
    color: #727272 !important;
}

.labelItem p{
    color: #727272 !important;
    font-size: 14px;
}

.labelDets{
    font-size: 14px;

}

.cardPegs{
    width: 80%;
    margin: auto;
    display: flex;
    gap: 12px;
}

.cardPeg{
    flex: 1;
    border-radius: 6px;
    aspect-ratio: 1.7;
    max-height: 60px;
    overflow: hidden;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 4px;
    transition: .2s ease-in-out;
    margin: 0 0 2rem 0;
    border: 1px #90909034 solid;
}

.cardPeg:hover{
    border: 1px #90909078 solid;
}
.cardPeg:hover img{
    transform: scale(1.05)
}

.cardPeg img{
    width: 100%;
    transition: .2s ease-in-out;
}

.pictModal{
    width: 100%;
    aspect-ratio: 1.7;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pictModal img{
    width: 100%;
}



@media(max-width: 737px){
    .top{
        width: 100%;
    }
    .switchWrap{
        padding: 10px;
    }
    .switchText{
        width: 100%;
        gap: 10px;
        justify-content: space-between;
        margin: 0 0 4px 0;
    }
    .switchText p{
        padding: 0;
        text-align: center;
    }
    .switchBar{
        width: 100%;
        height: 2px;
    }
    .searchFilter{
        padding: 1.4rem 0;
    }
    .searchPut{
        width: 100%;
        padding: 0px 10px;
        font-size: 14px;
    }
    .searchPut input{
        padding: 14px 0px;
        font-size: 14px;
    }
    .topBtns{
        width: 50%;
        padding: 10px;
    }

    .table::-webkit-scrollbar{
        display: none;
    }

    .table table{
        display: none;
    }

    .transList{
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 1rem;
    }
    .eachTrans{
        width: 100%;
        border-bottom: 1px #EDF2F7 solid;
        padding: 10px 0;
    }
    .et{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        padding: 0 0 6px 0;
        border-bottom: 1px #EDF2F7 dashed;
        
    }
    .etPic{
        width: 38px;
        height: 38px;
        border-radius: 1000px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .etPic img{
        height: 100%;
    }
    .etTitle{
        flex: 1;
        text-align: left;
        display: flex;
        flex-direction: column;
        gap: 6px;
    }
    .etTitle h4{
        font-size: 14px;
        color :#1a1a1a;
        font-weight: 600;
    }
    .etTitle p{
        font-size: 12px;
        color: #727272;
        font-weight: 500;
    }
    .etTime{
        display: flex;
        flex-direction: column;
        text-align: right;
        align-items: flex-end;
        gap: 6px;
    }
    .etTime p{
        font-size: 14px;
        font-weight: 500;
        color: #1a1a1a;
    }
    .etTime span{
        font-size: 12px;
        font-weight: 500;
        color: #727272;
    }
    .etDet{
        width: calc(100% - 48px);
        display: flex;
        align-items: center;
        margin: 0 0 0 auto;
        justify-content: space-between;
        padding: 4px 0;
    }
    .etDet p{
        font-size: 12px;
    }

    .etDet2{
        width: calc(100% - 48px);
        display: flex;
        align-items: center;
        margin: 0 0 0 auto;
        justify-content: center;
        padding: 4px 0;
        /* background: green; */
    }
    .etDet2 p{
        font-size: 12px;
        color: red;
    }
    .success{
        font-size: 10px;
        padding: 6px;
    }
    .pending{
        font-size: 10px;
        padding: 6px;
    }
    .failed{
        font-size: 10px;
        padding: 6px;
    }
    .cashTabs ul li{
        width: 50%;
    }
}