.login{
    width : 100%;
    height : 100vh;
    background: #fff;
}

.log{
    width : 100%;
    height : 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.left{
    width : 40%;
    max-width: 430px;
}

.contLeft{
    width: 100%;
    height: 100%
} 

.contLeft h2{
    font-size: 32px;
    text-align: center;
    color: #1a202c;
}

.contLeft p{
    font-size: 16px;
    color: #727272;
    text-align: center;
    margin: 16px 0 0 0;
}

.form{
    width: 100%;
    margin: 40px 0 0 0;
}

.form form{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
}

.labelInput{
    width: 100%;
}

.labelInput label{
    font-size: 14px;
    font-weight: 500;
}

.input{
    width: 100%;
    position: relative;
}

.input input{
    width: 100%;
    border: 1px #909090aa solid;
    border-radius: 6px;
    padding: 16px;
    transition: .2s ease-in-out;
}

.error{
    border: 1px red solid !important;
}

.notError{
    border: 1px #909090aa solid !important;
    transition: .2s ease-in-out;
}
.notError:focus{
    border: 1px #40196D solid !important;
}

.pError{
    color: red !important ;
    font-size: 11px !important;
    text-align: left !important;
    width: 100%; 
    margin: 0 !important;
}

.input input:focus{
    border: 1px #40196D solid;
}

.eyeCon{
    position: absolute;
    top: 50%;
    transform : translateY(-50%);
    right: 4%;
    color: #909090;
    font-size: 26px;
    cursor: pointer;
    transition: .2s ease-in-out;
}

.eyeCon:hover{
    color: #878787;
}
.eyeCon:active{
    color: #878787;
}

.createBtn {
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  padding: 16px;
  border-radius: 4px;
  border: none;
  background: #40196d;
  margin: .6rem 0 0 0;
  transition: 0.2s ease-in-out;
  cursor: pointer;
}

.createBtn:hover {
  transform: translateY(-4px);
}

.createBtn:active {
  transform: translateY(-1px);
}

.createBtn:disabled{
    background: #909090;
}

@media(max-width: 737px){
    .left{
        width: 90%;
    }
    .contLeft h2{
        font-size: 18px;
    }
    .contLeft p{
        font-size: 14px;
        margin : 6px 0 0 0;
    }
}